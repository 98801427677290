import React, { createContext, useContext } from 'react'

import { FiMenu } from 'react-icons/fi'
import { IconButton } from '@mui/material'
import theme from '../global/theme'

type BurgerMenuContextType = {
  isOpen: boolean
  toggleSidebar: () => void
}

const BurgerMenuContext = createContext<BurgerMenuContextType | undefined>(
  undefined
)

export const BurgerMenuProvider = ({
  children,
  isOpen,
  toggleSidebar,
}: {
  children: React.ReactNode
  isOpen: boolean
  toggleSidebar: () => void
}) => {
  return (
    <BurgerMenuContext.Provider value={{ isOpen, toggleSidebar }}>
      {children}
    </BurgerMenuContext.Provider>
  )
}

export const BurgerMenuButton = () => {
  const context = useContext(BurgerMenuContext)

  if (!context) {
    throw new Error('BurgerMenuButton must be used within a BurgerMenuProvider')
  }

  const { isOpen, toggleSidebar } = context

  return (
    <IconButton
      className='nav-toggle'
      onClick={toggleSidebar}
      aria-expanded={isOpen}
    >
      <FiMenu style={{ color: `${theme.colors.primary}` }} />
    </IconButton>
  )
}

export const useBurgerMenu = () => {
  const context = useContext(BurgerMenuContext)

  if (!context) {
    throw new Error('useBurgerMenu must be used within a BurgerMenuProvider')
  }

  return context
}

export default BurgerMenuContext
