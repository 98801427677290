import { Table, TableBody, TableContainer } from '@mui/material'

import Button from '../../../common/Button/Button.component'
import Card from '../../../common/Card/Card.component'
import { MdModeEdit } from 'react-icons/md'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import StringConstants from '../../../../constants/StringConstants'
import customerStore from '../../../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import theme from '../../../../global/theme'

const SectionWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 30px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  color: ${theme.colors.primary};
`
const Description = styled.p`
  padding: 20px 40px;
  width: 100%;
  font-weight: 600;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`
const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.contrastPrimary};
  }
`
const TableCell = styled(MuiTableCell)`
  color: ${theme.colors.textGrey};
  font-size: 14px;
  border-bottom: 0px;
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

interface DisplayCardProps {
  forSpouse: boolean
  effectiveDate: string
  handleEdit: () => void
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
}

const DisplayCard: React.FC<DisplayCardProps> = ({
  forSpouse,
  effectiveDate,
  handleEdit,
  disableMainNext,
  enableMainNext,
  handleHasNext,
}) => {
  return (
    <SectionWrapper>
      <Heading>Choose Your Current Plan</Heading>
      <Description>
        {StringConstants.INFO_STEPS_CURRENT_PLAN_DESCRIPTION}
      </Description>
      <Card cardType='readOnly' borderColor={theme.colors.primary}>
        <TableContainer>
          <Table>
            {!forSpouse ? (
              <TableBody>
                {customerStore.currentCoverageSelf && (
                  <TableRow key={`TypeOfInsurance`}>
                    <TableCell
                      width='50%'
                      align='right'
                      component='th'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Type of insurance
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {customerStore.currentCoverageSelf}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentMedicarePartBDateSelf && (
                  <TableRow key={`PartBEffectiveDate`}>
                    <TableCell
                      width='50%'
                      align='right'
                      component='th'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Part B Effective Date
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {effectiveDate}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentPlanTypeSelf && (
                  <TableRow key={`Plan`}>
                    <TableCell
                      width='50%'
                      component='th'
                      align='right'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Plan
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {customerStore.currentPlanTypeSelf}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentProviderSelf && (
                  <TableRow key={`Carrier`}>
                    <TableCell
                      width='50%'
                      component='th'
                      align='right'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Carrier
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      <span>{customerStore.currentProviderSelf}</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                {customerStore.currentCoverageSpouse && (
                  <TableRow key={`TypeOfInsurance`}>
                    <TableCell
                      width='50%'
                      align='right'
                      component='th'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Type of insurance
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {customerStore.currentCoverageSpouse}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentMedicarePartBDateSpouse && (
                  <TableRow key={`PartBEffectiveDate`}>
                    <TableCell
                      width='50%'
                      align='right'
                      component='th'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Part B Effective Date
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {effectiveDate}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentPlanTypeSpouse && (
                  <TableRow key={`Plan`}>
                    <TableCell
                      width='50%'
                      component='th'
                      align='right'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Plan
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      {customerStore.currentPlanTypeSpouse}
                    </TableCell>
                  </TableRow>
                )}
                {customerStore.currentProviderSpouse && (
                  <TableRow key={`Carrier`}>
                    <TableCell
                      width='50%'
                      component='th'
                      align='right'
                      scope='row'
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontWeight: 600,
                      }}
                    >
                      Carrier
                    </TableCell>
                    <TableCell
                      width='50%'
                      align='left'
                      style={{ fontWeight: 400 }}
                    >
                      <span>{customerStore.currentProviderSpouse}</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
      <Button
        width='200px'
        margin='0px 10px 0px 0px'
        bold={false}
        onClick={handleEdit}
        variant='contained'
        disabled={
          customerStore.isMAQuotesProcessing ||
          customerStore.isMSQuotesProcessing ||
          customerStore.isMPartDQuotesProcessing
        }
      >
        <>
          Edit
          <MdModeEdit style={{ position: 'relative', left: '50px' }} />
        </>
      </Button>
      {(customerStore.isMAQuotesProcessing ||
        customerStore.isMSQuotesProcessing ||
        customerStore.isMPartDQuotesProcessing) && (
        <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
      )}
    </SectionWrapper>
  )
}

export default observer(DisplayCard)
