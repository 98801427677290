import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import Button from '../../common/Button/Button.component'
import CloseIconButton from '../../common/Button/CloseIconButton.component'
import MedicalShieldIcon from '../../common/Icon/MedicalShieldIcon.component'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import RouteConstants from '../../../constants/RouteConstants'
import SearchIcon from '../../common/Icon/SearchIcon.component'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  redirect: string | null
}

export const options = [
  'Our online process helps match you with the plan that best fits your needs based on the information you provide.',
  'Enter your ZIP Code to view available plans in your area.',
]

const Container = styled.div`
  min-height: 220px;

  h2 {
    color: #272f69;
    padding-bottom: 20px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    color: ${theme.colors.textDark};

    span {
      color: #ba0000;
      font-weight: bold;
    }
  }
  .highlight {
    color: #262e67;
    font-weight: 700;
    padding-bottom: 20px;
    font-size: 18px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
const ModalButton = styled(Link)<{ type: 'Yes' | 'No' }>`
  width: ${(props) => (props.type === 'Yes' ? '50%' : '30%')};
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`
const CloseButtonContainer = styled.div`
  display: 'flex';
  justify-content: 'end';
`

const InfoStepsPrompt: React.FC<ModelProps> = ({ setOpen, redirect }) => {
  const [response, setResponse] = useState<string>('')
  const navigate = useNavigate()
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'self-start',
        }}
      >
        <CloseButtonContainer>
          <CloseIconButton
            onClick={(e) => setOpen(false)}
            fontSize='14px'
            textColor={theme.colors.primary}
            iconType='bold'
          />
        </CloseButtonContainer>
        <h2 style={{ paddingTop: '10px', textAlign: 'center' }}>
          How can we assist you? Let us help you find a plan that fits your
          needs.
        </h2>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '16px',
        }}
      >
        <MedicalShieldIcon />
      </div>
      <p
        style={{
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: '400',
          color: `${theme.colors.textGrey}`,
        }}
      >
        Answering a few simple questions will help highlight which Medicare plan
        type fits you.
      </p>
      <RadioGroup
        radioButtonStyle={{ background: '#FBFBFB', borderRadius: '5px' }}
        onChange={(e) => {
          setResponse(e.target.value)
        }}
        radioButtons={[]}
        value={''}
        customRadioButtons={[
          'Recommend a plan for me',
          'I’m just browsing.',
        ].map((value, index) => {
          return {
            customLabel: '',
            customLabelComponent: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'start',
                }}
              >
                <div
                  style={{
                    fontWeight: '700',
                    color: `${theme.colors.textGrey}`,
                  }}
                >
                  {value}
                </div>
                <div
                  style={{
                    fontWeight: '400',
                    color: `${theme.colors.textGrey}`,
                  }}
                >
                  {options[index]}
                </div>
              </div>
            ),
            value: value,
          }
        })}
      ></RadioGroup>
      <div>
        <ModalButton
          type='Yes'
          to={
            response === 'Recommend a plan for me'
              ? redirect
                ? redirect
                : RouteConstants.INFO_STEPS_PRESCRIPTION
              : ''
          }
        >
          <Button
            variant='contained'
            bold={false}
            width='100%'
            color='secondary'
            description='Dashboard Info Steps Promt - CTA'
            id='findmedicareplans'
            disabled={isEmpty(response)}
            onClick={() => {
              if (response === 'Recommend a plan for me') {
                navigate(
                  redirect ? redirect : RouteConstants.INFO_STEPS_PRESCRIPTION
                )
              } else {
                setOpen(false)
              }
            }}
            startIcon={<SearchIcon color={'#FFFFFF'} />}
          >
            {/* <PharmacyIcon color={'#FFFFFF'} /> */}
            Find my Medicare Plans
          </Button>
        </ModalButton>
        <ModalButton type='No' to=''>
          <Button
            variant='contained'
            color='primary'
            bold={false}
            width='100%'
            onClick={(e) => setOpen(false)}
            description='Dashboard Info Steps Prompt - Skip'
          >
            Skip for now
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default InfoStepsPrompt
