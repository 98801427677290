import ApiManager from '../api/ApiManager'
import { AxiosResponse } from 'axios'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { QuestionnaireI } from '../interface/questionnaire/QuestionnaireInterface'
import { SaveQuestionnaireRequestI } from '../interface/questionnaire/SaveQuestionnaireRequestInterface'
import UrlConstants from '../constants/UrlConstants'

export const getQuestionnaires = (
  isSpouse: boolean,
  type: 'HEALTH' | 'PLAN_TYPE' | 'CURRENT_PLAN'
): Promise<QuestionnaireI[]> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_HEALTH_QUESTIONNAIRE.USECASE,
      HttpMethods.GET_METHOD,
      {
        'for-spouse': isSpouse,
        type,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addResponsesForQuestionnaires = (
  forSpouse: boolean,
  payload: SaveQuestionnaireRequestI[]
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.POST_HEALTH_QUESTIONNAIRE_RESPONSE.USECASE,
      HttpMethods.POST_METHOD,
      { $forSpouse: forSpouse, data: payload }
    )
      .then((res: AxiosResponse<string>) => {
        if (res.status === 200) {
          resolve(true)
        } else {
          reject(res)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
