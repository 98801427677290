import { Box, Chip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import {
  getCustomerDetails,
  removeSpouseDetails,
} from '../../../../service/customer'
import {
  getMSFilters,
  getMSPlans,
  getMedigapChartDetails,
} from '../../../../service/quotes'

import { ActionContext } from '../../../../cra'
import ApiManager from '../../../../api/ApiManager'
import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import { CheckBoxType } from '../../../../interface/common/CheckBoxType'
import CompletePlanDetailsPage from '../../../../pages/CompletePlanDetailsPage/CompletePlanDetailsPage'
import EffectiveDateFilterChangeConfirmation from '../../Modals/EffectiveDateFilterChangeConfirmation'
import FilterBox from '../../Common/FilterBox.component.tsx/FilterBox'
import FilterSupplement from './FilterSupplement'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanRequestFilter } from '../../../../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MSPlansDisplayFilters } from '../../../../interface/quotes/mSupplementPlans/MSPlansDisplayFilters'
import { MSPlansFilters } from '../../../../interface/quotes/mSupplementPlans/MSPlansFilters'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import MedicareSupplementCard from './MedicareSupplementCard'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PrimarySpouseToggle from '../../Common/PrimarySpouseToggle'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RecommendedMSCards from './RecommendedMSCards.component'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../../../constants/UrlConstants'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { getContact } from '../../../../utils/getContact'
import moment from 'moment'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useBurgerMenu } from '../../../../context/SidebarContext'
import { useReactToPrint } from 'react-to-print'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const CardsWrapper = styled.div`
  flex: 1;
  max-width: 1200px;
  padding: 20px 60px;

  .recommendation-header {
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding-bottom: 20px;
    h2 {
      color: ${theme.colors.primary};
      font-weight: 700;
      font-size: 28px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.textGrey};
    }
  }

  @media screen and (max-width: 950px) {
    padding: 20px;
  }
`
const UtilityContainer = styled.div`
  display: flex;
  gap: 10px;
  .filter-toggle {
    width: 26%;
    display: none;
    border: 1px solid ${theme.colors.textGrey};
    align-items: center;
    color: ${theme.colors.textGrey};
    font-weight: 600;
    cursor: pointer;
    border-radius: 16px;
    padding: 5px 10px;
    transition: box-shadow 0.4s;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.4s;
    }
    @media screen and (max-width: 950px) {
      display: flex;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  display: block;

  .bottom-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: initial;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MedicareSupplement: React.FC<{
  plans: MSPlan[]
  onDataChange: (data: MSPlan[]) => void
  setMSPlanTotalCount: (data: number) => void
  msPlanTotalCount: number | null
  setShowAddSpouseConfirmation: (flag: boolean) => void
  fetchQuotesPullStatus: (planYear?: number) => void
}> = ({
  plans,
  onDataChange,
  setMSPlanTotalCount,
  msPlanTotalCount,
  setShowAddSpouseConfirmation,
  fetchQuotesPullStatus,
}) => {
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >(
    msPlanFiltersStore.isPrimaryAndSpouseOption
      ? msPlanFiltersStore.spouseFilters?.sortOptions?.map((value) => ({
          title: value,
          value,
        }))
      : msPlanFiltersStore.msPlanFilters?.sortOptions?.map((value) => ({
          title: value,
          value,
        }))
  )

  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>(
    !msPlanFiltersStore.isPrimaryAndSpouseOption
      ? {
          index: msPlanFiltersStore.msPlanFilters.sortOptions?.findIndex(
            (sortOption) => {
              return (
                sortOption ===
                msPlanFiltersStore.msPlanFilters.selectedSortOption
              )
            }
          ),
          label: msPlanFiltersStore.msPlanFilters.selectedSortOption!,
        }
      : {
          index: msPlanFiltersStore.spouseFilters.sortOptions?.findIndex(
            (sortOption) => {
              return (
                sortOption ===
                msPlanFiltersStore.spouseFilters.selectedSortOption
              )
            }
          ),
          label: msPlanFiltersStore.spouseFilters.selectedSortOption!,
        }
  )

  const [sectionLoader, setSectionLoader] = useState<boolean>(true)
  const [plansLoader, setPlansLoader] = useState<boolean>(true)
  const [paginationLoader, setPaginationLoader] = useState<boolean>(true)
  const [userOption, setUserOption] = useState<boolean>(
    msPlanFiltersStore.isPrimaryAndSpouseOption
  )
  const [msPlanFilters, setMSPlanFilters] = useState<MSPlansDisplayFilters>(
    msPlanFiltersStore.isPrimaryAndSpouseOption
      ? msPlanFiltersStore.spouseFilters
      : msPlanFiltersStore.msPlanFilters
  )
  const [msPlansRequestBody, setMSPlansRequestBody] =
    useState<MSPlanRequestFilter>(
      !msPlanFiltersStore.isPrimaryAndSpouseOption
        ? msPlanFiltersStore.msPlanRequestFilter
        : msPlanFiltersStore.spouseRequestFilters
    )
  const [
    openEffectiveDateFilterConfirmationPopup,
    setOpenEffectiveDateFilterConfirmationPopup,
  ] = useState<boolean>(false)

  const getStepValue = (percentage: number, total: number): number => {
    return Math.round((percentage / 100) * total)
  }

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const [completePlanDetailModalActive, setCompletePlanDetailModalActive] =
    React.useState<boolean>(false)
  const [completeDetailPlanId, setCompleteDetailPlanId] =
    React.useState<string>('')

  const { trackCurrentPage } = React.useContext(ActionContext)

  const { isOpen, toggleSidebar } = useBurgerMenu()

  const filterSupplementRef = useRef<any>(null)

  const [clearFilterState, setClearFilterState] = React.useState(false)

  const [medigapChartDetails, setMedigapChartDetails] = React.useState<any>({})

  const printRef = useRef(null)

  const reactToPrint = useReactToPrint({
    content: () => printRef.current,
  })

  useEffect(() => {
    if (isEmpty(msPlanFilters)) getFilters(userOption)
    getMedigapChartDetails()
      .then((response) => {
        setMedigapChartDetails(response)
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'retrieve medigap chart details.'
          ),
          snackbarType: 'error',
        })
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(msPlansRequestBody)) getPlans(msPlansRequestBody)
  }, [msPlansRequestBody])

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Supplements')
  }, [trackCurrentPage])

  // useEffect(() => {
  //   if (!isEmpty(msPlanFiltersStore.getMSPlansFilters())) {
  //     const planOptions = msPlanFiltersStore
  //       .getMSPlansFilters()
  //       .sortOptions.map((option) => {
  //         return { title: option, value: option }
  //       })

  //     setSortPlanOptions(planOptions)
  //     setCurrentSortOption({
  //       label: msPlanFiltersStore.getSelectedSortOption()!,
  //       index: planOptions.findIndex((option) => {
  //         return option.title === msPlanFiltersStore.getSelectedSortOption()!
  //       }),
  //     })
  //   }
  // }, [
  //   JSON.stringify(msPlanFiltersStore.getMSPlansFilters().selectedSortOption),
  // ])

  // useEffect(() => {
  //   if (!isEmpty(medicareSupplementPlans)) {
  //     msPlanFiltersStore.setPlansFetched(true)
  //     msPlanFiltersStore.setPaginationApplied(false)
  //     msPlanFiltersStore.setPaginationLoader(false)
  //   }
  //   msPlanFiltersStore.setClearFilters(false)
  // }, [medicareSupplementPlans])

  // useEffect(() => {
  //   if (!isUndefined(msPlanFiltersStore.getMSPlansFilters())) {
  //     setMSPlanFilter({
  //       ...msPlanFiltersStore.getMSPlansFilters(),
  //     })
  //   }
  // }, [msPlanFiltersStore.getMSPlansFilters()])

  const getFilters = (forSpouse: boolean) => {
    getMSFilters(forSpouse)
      .then((filters: any) => {
        if (!isEmpty(filters)) {
          const tempMSPlanFilters: MSPlansFilters = filters

          const startOfNextMonth =
            moment()
              .add(1, 'month')
              .toISOString()
              .split('T')[0]
              .substring(0, 8) + '01'

          const effectiveDate = isEmpty(customerStore.msEffectiveDateFilterSelf)
            ? startOfNextMonth
            : customerStore.msEffectiveDateFilterSelf!.split('T')[0]

          msPlanFiltersStore.setEffectiveDate(effectiveDate)

          const msPlanDisplayFilters: MSPlansDisplayFilters = {
            clearFiltersApplied: false,
            selectedSortOption: QuotesConstants.initialMSPlanSortOption,
            ambestRatings: tempMSPlanFilters.ambestRatings.map(
              (ambestRating, index) => {
                return {
                  id: index,
                  checked: false,
                  label: ambestRating,
                  value: ambestRating,
                }
              }
            ),
            carriers: tempMSPlanFilters.carriers.map((carrier, index) => {
              return {
                id: index,
                checked: false,
                label: carrier,
                value: carrier,
              }
            }),
            planTypes: tempMSPlanFilters.planTypes.map((planType, index) => {
              return {
                id: index,
                checked: false,
                label: `Plan ${planType} ${
                  planType === 'HDG' || planType === 'HDF'
                    ? '(High Deductible)'
                    : ''
                }`,
                value: planType,
              }
            }),
            sortOptions: tempMSPlanFilters.sortOptions,
            annualDeductible: {
              currentValue: tempMSPlanFilters.annualDeductible.end,
              maxValue: tempMSPlanFilters.annualDeductible.end,
              minValue: tempMSPlanFilters.annualDeductible.start,
              stepValue: getStepValue(
                10,
                tempMSPlanFilters.annualDeductible.end -
                  tempMSPlanFilters.annualDeductible.start
              ),
            },
            monthlyPremium: {
              currentValue: tempMSPlanFilters.monthlyPremium.end / 100,
              maxValue: tempMSPlanFilters.monthlyPremium.end / 100,
              minValue: tempMSPlanFilters.monthlyPremium.start / 100,
              stepValue: getStepValue(
                10,
                tempMSPlanFilters.monthlyPremium.end / 100 -
                  tempMSPlanFilters.monthlyPremium.start / 100
              ),
            },
          }
          const storedPlanType = customerStore.filterCoverageSelf.includes(
            'Medicare Supplement'
          )
            ? customerStore.filterPlanTypeSelf.includes('High Deductible')
              ? customerStore.filterPlanTypeSelf.includes('Plan F')
                ? 'Plan HDF (High Deductible)'
                : customerStore.filterPlanTypeSelf.includes('Plan G')
                ? 'Plan HDG (High Deductible)'
                : ''
              : customerStore.filterPlanTypeSelf
            : ''

          const planTypes =
            storedPlanType !== ''
              ? tempMSPlanFilters.planTypes.filter((planType) =>
                  storedPlanType.includes('Plan ' + planType)
                )
              : []
          const checkedPlanTypes =
            storedPlanType !== ''
              ? tempMSPlanFilters.planTypes.map((type, index) => {
                  const checked = storedPlanType.includes('Plan ' + type)
                  return {
                    id: index,
                    checked: checked,
                    label: `Plan ${type} ${
                      type === 'HDG' || type === 'HDF'
                        ? '(High Deductible)'
                        : ''
                    }`,
                    value: type,
                  }
                })
              : []

          if (planTypes.length !== 0) {
            msPlanDisplayFilters.planTypes = checkedPlanTypes
          }

          //TODO check this
          if (
            customerStore.filterCoverageSelf.includes('Medicare Supplement')
          ) {
            customerStore.setFiltersInfoStep({
              coverageSelf: '',
              planTypeSelf: '',
              providerSelf: '',
            })
          }

          setMSPlanFilters(msPlanDisplayFilters)

          msPlanFiltersStore.setSelectedFilters(
            [],
            msPlanFiltersStore.isPrimaryAndSpouseOption
          )
          setSelectedFilters(
            msPlanFiltersStore.getSelectedFilters(
              msPlanFiltersStore.isPrimaryAndSpouseOption
            )
          )
          if (msPlanFiltersStore.isPrimaryAndSpouseOption) {
            handleRequestBodyChange(
              {
                // carriers: carriers,
                planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMSPlanSortOption,
                },
                forSpouse: true,
              },
              true
            )
            msPlanFiltersStore.spouseFilters = msPlanDisplayFilters
          } else {
            handleRequestBodyChange(
              {
                // carriers: carriers,
                planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMSPlanSortOption,
                },
                forSpouse: false,
              },
              false
            )
            msPlanFiltersStore.setMSPlansFilters(msPlanDisplayFilters)
          }

          const planOptions = msPlanDisplayFilters.sortOptions.map((option) => {
            return { title: option, value: option }
          })

          setSortPlanOptions(planOptions)

          setCurrentSortOption({
            label: msPlanDisplayFilters.selectedSortOption!,
            index: planOptions.findIndex((option) => {
              return option.title === msPlanDisplayFilters.selectedSortOption!
            }),
          })

          //TODO Remove after working

          // msPlanFiltersStore.setMSPlanRequestFilters({
          //   // carriers: carriers,
          //   planTypes: planTypes,
          //   paginationDetail: {
          //     page: PaginationConstants.DEFAULT_PAGE,
          //     size: PaginationConstants.DEFAULT_PAGE_SIZE,
          //   },
          //   sortDetail: {
          //     order: 'ASC',
          //     type: QuotesConstants.initialMSPlanSortOption,
          //   },
          // })

          //TODO Check this
          customerStore.setMSPageSize(
            customerStore.msPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
          )
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'No Medicare Supplement Plans Present',
            snackbarType: 'success',
          })

          setPlansLoader(false)
          setSectionLoader(false)
          setPaginationLoader(false)
        }
      })
      .catch((err) => {
        // snackbarStore.set({
        //   snackbarOpen: true,
        //   snackbarMessage: StringConstants.INTERNAL_SERVER_ERROR,
        //   snackbarType: 'error',
        // })
        setPlansLoader(false)
        setSectionLoader(false)
        setPaginationLoader(false)
      })
  }

  const getPlans = (payload: MSPlanRequestFilter) => {
    getMSPlans(payload)
      .then((medicareSupplementPlans: any) => {
        const convertedMSPlans: MSPlan[] = []
        for (let msPlan of medicareSupplementPlans.data) {
          convertedMSPlans.push(msPlan)
        }

        // if (
        //   (msPlanFiltersStore.prevSortType !== null &&
        //     msPlanFiltersStore.prevSortType !==
        //       msPlanFiltersStore.getMSPlansFilters().selectedSortOption) ||
        //   customerStore.msPageNumber === PaginationConstants.DEFAULT_PAGE
        // ) {
        //   msPlanFiltersStore.setPrevSortType(
        //     msPlanFiltersStore.getMSPlansFilters().selectedSortOption
        //   )
        //   customerStore.setMSPageNumber(
        //     customerStore.msPageSize / PaginationConstants.DEFAULT_PAGE_SIZE
        //   )
        //   setMSPlans(convertedMSPlans)
        // } else {
        //   setMSPlans(cloneDeep(msPlans.concat(convertedMSPlans)))
        // }
        if (
          payload.paginationDetail?.page === PaginationConstants.DEFAULT_PAGE
        ) {
          onDataChange(cloneDeep(convertedMSPlans))
        } else {
          onDataChange(cloneDeep(plans.concat(convertedMSPlans)))
        }

        setMSPlanTotalCount(
          medicareSupplementPlans.totalCount
            ? medicareSupplementPlans.totalCount
            : medicareSupplementPlans.data.length
        )

        //TODO Check this after implementation of MS Plans
        // msPlanFiltersStore.setIsEffectiveDateFilterApplied(false)
      })
      .catch((err) => {})
      .finally(() => {
        setSectionLoader(false)
        setPlansLoader(false)
        setPaginationLoader(false)
      })
  }

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]

    if (currentSortOption?.label !== sortOption) {
      setCurrentSortOption({
        label: sortOption,
        index: sortPlanOptions.findIndex((option) => {
          return option.title === sortOption
        }),
      })

      handleRequestBodyChange(
        {
          sortDetail: { order: 'ASC', type: sortOption },
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        userOption
      )

      if (msPlanFiltersStore.isPrimaryAndSpouseOption) {
        msPlanFiltersStore.spouseFilters.selectedSortOption = sortOption
      } else msPlanFiltersStore.msPlanFilters.selectedSortOption = sortOption
      setPlansLoader(true)
    }
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handlePaginationChange = () => {
    setPaginationLoader(true)
    handleRequestBodyChange(
      {
        paginationDetail: {
          page:
            msPlansRequestBody.paginationDetail?.page! +
            PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
      },
      userOption
    )

    customerStore.setMSPageSize(
      customerStore.msPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
    customerStore.setMSPageNumber(
      customerStore.msPageNumber + PaginationConstants.DEFAULT_PAGE
    )
  }

  const toggleEffectiveDateFilterPopupOpen = () => {
    setOpenEffectiveDateFilterConfirmationPopup(
      !openEffectiveDateFilterConfirmationPopup
    )
  }

  const handleConfirmEffectiveDateFilterChange = () => {
    msPlanFiltersStore.setEffectiveDate(msPlanFiltersStore.tempEffectiveDate)
    msPlanFiltersStore.setTempEffectiveDate('')
    // msPlanFiltersStore.setIsEffectiveDateFilterApplied(true)

    // // setEffectiveDateChanged(true)
    // // msPlanFiltersStore.setTotalCount(null)
    // // msPlanFiltersStore.setPlansFetched(false)
    // // setMSPlans([])
    // // msPlanFiltersStore.clearMSPlanRequestFilters()
    // // msPlanFiltersStore.setFiltersFetched(false)
    toggleEffectiveDateFilterPopupOpen()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (!isEmpty(msPlanFiltersStore.tempEffectiveDate)) {
      setOpenEffectiveDateFilterConfirmationPopup(true)
    }
  }, [msPlanFiltersStore.tempEffectiveDate])

  const handleRejectEffectiveDateFilterChange = () => {
    msPlanFiltersStore.setTempEffectiveDate('')
    toggleEffectiveDateFilterPopupOpen()
  }

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    msPlanFiltersStore.isPrimaryAndSpouseOption
      ? msPlanFiltersStore.spouseSelectedFilters
      : msPlanFiltersStore.selectedFilters
  )

  const handleClearFilters = () => {
    setSectionLoader(true)
    msPlanFiltersStore.clearRequestFilters(
      msPlanFiltersStore.isPrimaryAndSpouseOption
    )
    setSelectedFilters([])
    setMSPlanFilters({} as MSPlansDisplayFilters)
    getFilters(msPlanFiltersStore.isPrimaryAndSpouseOption)
  }

  const handleRequestBodyChange = (
    fieldsChanged: MSPlanRequestFilter,
    forSpouse: boolean
  ) => {
    let requestBody: MSPlanRequestFilter = {}
    if (forSpouse) {
      requestBody = msPlanFiltersStore.spouseRequestFilters
      setMSPlansRequestBody({ ...requestBody, ...fieldsChanged })
      msPlanFiltersStore.spouseRequestFilters = {
        ...requestBody,
        ...fieldsChanged,
      }
    } else {
      requestBody = msPlanFiltersStore.msPlanRequestFilter
      setMSPlansRequestBody({ ...requestBody, ...fieldsChanged })
      msPlanFiltersStore.msPlanRequestFilter = {
        ...requestBody,
        ...fieldsChanged,
      }
    }
  }

  const updateFilters = (unFilter: string) => {
    const tempSelectedFilters = selectedFilters.filter(
      (filter) => filter !== unFilter
    )

    setSelectedFilters(tempSelectedFilters)
    msPlanFiltersStore.setSelectedFilters(
      tempSelectedFilters,
      msPlanFiltersStore.isPrimaryAndSpouseOption
    )

    if (msPlanFilters) {
      let fieldsChanged: MSPlanRequestFilter = msPlansRequestBody

      const checkedPlanTypes = msPlanFilters.planTypes?.map((item) =>
        item.value === unFilter || item.value === unFilter
          ? { ...item, checked: !item.checked }
          : item
      )

      fieldsChanged['planTypes'] = checkedPlanTypes
        .filter((checkedPlanType: CheckBoxType) => checkedPlanType.checked)
        .map((checkedPlanType: CheckBoxType) => checkedPlanType.value)

      const checkedCarriers = msPlanFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )
      fieldsChanged['carriers'] = checkedCarriers
        .filter((checkedCarrier: CheckBoxType) => checkedCarrier.checked)
        .map((checkedCarrier: CheckBoxType) => checkedCarrier.value)

      const checkedAMBestRatings = msPlanFilters.ambestRatings?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['ambestRatings'] = checkedAMBestRatings
        .filter(
          (checkedAMBestRating: CheckBoxType) => checkedAMBestRating.checked
        )
        .map((checkedAMBestRating: CheckBoxType) => checkedAMBestRating.value)

      const tempFilters = {
        ...msPlanFilters,
        planTypes: checkedPlanTypes,
        carriers: checkedCarriers,
        ambestRatings: checkedAMBestRatings,
      }
      setMSPlanFilters(tempFilters)
      if (msPlanFiltersStore.isPrimaryAndSpouseOption)
        msPlanFiltersStore.setSpouseFilter(tempFilters)
      else msPlanFiltersStore.setMSPlansFilters(tempFilters)
      setPlansLoader(true)
      handleRequestBodyChange(
        fieldsChanged,
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )
    }
  }

  const handleUserChange = (forSpouse: boolean) => {
    setSectionLoader(true)
    setUserOption(forSpouse)

    let isFiltersCalled = false
    msPlanFiltersStore.isPrimaryAndSpouseOption = forSpouse
    if (forSpouse) {
      if (isEmpty(msPlanFiltersStore.spouseFilters)) {
        isFiltersCalled = true
        getFilters(forSpouse)
      } else {
        setMSPlanFilters(msPlanFiltersStore.spouseFilters)
        setCurrentSortOption({
          index: msPlanFiltersStore.spouseFilters?.sortOptions?.findIndex(
            (option) => {
              return (
                option === msPlanFiltersStore.spouseFilters?.selectedSortOption!
              )
            }
          ),
          label: msPlanFiltersStore.spouseFilters.selectedSortOption!,
        })
        setSelectedFilters(msPlanFiltersStore.spouseSelectedFilters)
      }
    } else {
      if (isEmpty(msPlanFiltersStore.msPlanFilters)) {
        isFiltersCalled = true
        getFilters(forSpouse)
      } else {
        setMSPlanFilters(msPlanFiltersStore.msPlanFilters)
        setCurrentSortOption({
          index: msPlanFiltersStore
            .getMSPlansFilters()
            ?.sortOptions?.findIndex((option) => {
              return (
                option ===
                msPlanFiltersStore.getMSPlansFilters()?.selectedSortOption!
              )
            }),
          label: msPlanFiltersStore.getMSPlansFilters().selectedSortOption!,
        })
        setSelectedFilters(msPlanFiltersStore.selectedFilters)
      }
    }
    if (!isFiltersCalled) {
      handleRequestBodyChange({ forSpouse }, forSpouse)
    }
  }

  const handleSpouseRemove = () => {
    setSectionLoader(true)
    removeSpouseDetails()
      .then(() => {
        getCustomerDetails()
          .then((customerDetails: any) => {
            customerStore.set(customerDetails)
            getFilters(msPlanFiltersStore.isPrimaryAndSpouseOption)
          })
          .catch((err) => {
            throw err
          })
      })
      .catch(() => {
        setSectionLoader(false)
      })
  }

  return (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}

      {completePlanDetailModalActive && !isEmpty(completeDetailPlanId) ? (
        <ModalComponent
          maxWidth={'none'}
          restrictScroll={true}
          setOpen={() => setCompletePlanDetailModalActive(false)}
        >
          <CompletePlanDetailsPage
            medicarePlanId={completeDetailPlanId}
            medicarePlanType='ms'
            printComponentRef={printRef}
            reactToPrint={reactToPrint}
          />
        </ModalComponent>
      ) : (
        <></>
      )}

      {/* Hidden Printable Content */}
      {/* <div style={{ display: 'none' }}>
        <div ref={printRef}>
          <CompletePlanDetailsPage
            medicarePlanId={completeDetailPlanId}
            medicarePlanType='ms'
            printComponentRef={printRef}
            reactToPrint={reactToPrint}
          />
        </div>
      </div> */}

      {openEffectiveDateFilterConfirmationPopup && (
        <ModalComponent
          width={450}
          setOpen={handleRejectEffectiveDateFilterChange}
          hideCloseButton={true}
          description='Popup - Confirm Effective Date Filter Change'
        >
          <EffectiveDateFilterChangeConfirmation
            onConfirm={handleConfirmEffectiveDateFilterChange}
            onReject={handleRejectEffectiveDateFilterChange}
          />
        </ModalComponent>
      )}

      {!sectionLoader ? (
        <>
          {!isEmpty(msPlanFilters) && (
            <FilterSupplement
              filterRef={filterSupplementRef}
              showFilter={isOpen}
              handleShowFilter={toggleSidebar}
              setSelectedFilters={(Value: string[]) => {
                setSelectedFilters(Value)
              }}
              msPlanFilters={msPlanFilters}
              setMSPlanFilter={setMSPlanFilters}
              handleRequestBodyChange={handleRequestBodyChange}
              setPlanLoader={setPlansLoader}
              fetchQuotesPullStatus={fetchQuotesPullStatus}
              selectedFilters={selectedFilters}
            />
          )}
          <CardsWrapper>
            {/* {currentSortOption.label === 'Popularity' */}
            {customerStore.recommendedPlanType ===
              MedicareQuotesType.MEDICARE_SUPPLEMENT &&
              plans.length > 0 && (
                <div className='recommendation-header'>
                  <h2>Here are the Best Plans for You</h2>
                  <p>
                    Make changes to your drugs, doctors, pharmacy, and/or your
                    desired benefits and see how it affects your recommendation.
                  </p>
                </div>
              )}

            {!isEmpty(msPlanFilters) && (
              <>
                <FilterContainer>
                  <div className='bottom-wrapper'>
                    <PrimarySpouseToggle
                      handleUserChange={handleUserChange}
                      forSpouse={userOption}
                    />
                    <UtilityContainer>
                      {sortPlanOptions && (
                        <SortBy
                          options={sortPlanOptions}
                          value={currentSortOption}
                          onChange={handleSortChange}
                        />
                      )}
                    </UtilityContainer>
                  </div>
                </FilterContainer>
                <FilterBox
                  handleClearFilters={handleClearFilters}
                  selectedFilters={selectedFilters}
                  updateFilters={updateFilters}
                  key={'MS_FILTER_BOX'}
                />
              </>
            )}

            <>
              {plansLoader ? (
                <ThreeDotsWrapper>
                  <ThreeDots color='#222C69' height={80} width={80} />
                </ThreeDotsWrapper>
              ) : plans.length > 0 ? (
                <>
                  {/* {currentSortOption.label === 'Popularity' */}
                  {customerStore.recommendedPlanType ===
                    MedicareQuotesType.MEDICARE_SUPPLEMENT && (
                    <RecommendedMSCards
                      recommendedPlan={plans[0]}
                      goodChoicePlan={plans.length > 1 ? plans[1] : undefined}
                      setCompletePlanDetailModalActive={
                        setCompletePlanDetailModalActive
                      }
                      setCompleteDetailPlanId={setCompleteDetailPlanId}
                      userOption={userOption}
                      onEnroll={handleOnEnroll}
                      medigapChartDetails={medigapChartDetails}
                      handleSpouseRemove={handleSpouseRemove}
                      setShowAddSpouseConfirmation={
                        setShowAddSpouseConfirmation
                      }
                    />
                  )}
                  {(customerStore.recommendedPlanType !==
                  MedicareQuotesType.MEDICARE_SUPPLEMENT
                    ? plans
                    : plans.slice(2)
                  ).map((cardData: MSPlan, index) => (
                    <div key={cardData.id}>
                      <MedicareSupplementCard
                        setCompletePlanDetailModalActive={
                          setCompletePlanDetailModalActive
                        }
                        setCompleteDetailPlanId={setCompleteDetailPlanId}
                        userOption={userOption}
                        msPlan={cardData}
                        onEnroll={handleOnEnroll}
                        medigapChartDetails={medigapChartDetails}
                        handleSpouseRemove={handleSpouseRemove}
                        setShowAddSpouseConfirmation={
                          setShowAddSpouseConfirmation
                        }
                      />
                      {index % 2 !== 0 && (
                        <CallUsNow
                          heading='Not sure which Medicare plan is right for you?'
                          message='Our licensed insurance agents are here to help'
                          phoneNumber={getContact(customerStore.getBrandName())}
                        />
                      )}
                    </div>
                  ))}
                  {!paginationLoader ? (
                    <PaginationWrapper>
                      {!isNull(msPlanTotalCount) &&
                        Math.ceil(
                          msPlanTotalCount /
                            PaginationConstants.DEFAULT_PAGE_SIZE
                        ) >
                          (msPlansRequestBody.paginationDetail
                            ? msPlansRequestBody.paginationDetail.page
                            : 0) && (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={handlePaginationChange}
                          >
                            Load More Plans
                          </Button>
                        )}
                    </PaginationWrapper>
                  ) : (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </>
              ) : (
                <NoPlanFoundCard />
              )}
            </>

            {/* {msPlanFiltersStore.isFilterApplied() ||
            msPlanFiltersStore.isFiltersCleared() ? (
              <>
                <ThreeDotsWrapper>
                  <ThreeDots color='#222C69' height={80} width={80} />
                </ThreeDotsWrapper>
              </>
            ) : (
              <>
                {medicareSupplementPlans.length > 0 ? (
                  <></>
                ) : (
                  <>
                    {msPlanFiltersStore.isPlanFetched() &&
                    !msPlanFiltersStore.paginationLoader ? (
                      <NoPlanFoundCard />
                    ) : (
                      <ThreeDotsWrapper>
                        <ThreeDots color='#222C69' height={80} width={80} />
                      </ThreeDotsWrapper>
                    )}
                  </>
                )}
              </>
            )} */}
          </CardsWrapper>
        </>
      ) : (
        <ThreeDotsWrapper>
          <ThreeDots color='#222C69' height={80} width={80} />
        </ThreeDotsWrapper>
      )}
      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicareSupplement)
