import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import React, { useEffect, useState } from 'react'

import Button from '../../common/Button/Button.component'
import { Checkbox } from '@mui/material'
import { MSPlan } from '../../../interface/quotes/mSupplementPlans/MSPlan'
import { MedicareQuotesType } from '../../../enums/MedicareQuoteTypeEnum'
import SavePlanIconButton from '../../common/SavePlan/SavePlanIconButton'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import userActionStore from '../../../datastore/UserActionStore'

interface PlanCardFooterProps {
  id: string
  planId: string
  planType: string
  planName: string
  favourite: boolean
  handleRemoveFavourite: (() => void) | undefined
  showCompareCheckbox: boolean
  handleAddToCompare: (e: React.ChangeEvent<HTMLInputElement>) => void
  isExpanded: boolean
  handleExpandCard?: (planName: string) => void
  medicareQuoteType: MedicareQuotesType
  forSpouse: boolean
  spousePlanId?: string | undefined
  msPlan?: MSPlan
  isPrimarySaved: boolean
  handleExpandMSCard?: (msPlan: MSPlan) => void
  isInGrid?: boolean
}

const FooterWrapper = styled.div<{ isInGrid: boolean }>`
  padding: 10px 30px;
  display: flex;
  justify-content: ${(props) => (props.isInGrid ? 'center' : 'space-between')};
  align-items: center;
  flex-wrap: wrap;
  .left-side {
    display: flex;
    align-items: center;
    color: ${theme.colors.textGrey};
    gap: 20px;
    span {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
    justify-content: center;
    .left-side {
      justify-content: space-between;
      width: 100%;
    }
  }
`

const PlanCardFooter: React.FC<PlanCardFooterProps> = ({
  id,
  planId,
  favourite,
  isPrimarySaved,
  handleRemoveFavourite,
  showCompareCheckbox,
  planName,
  planType,
  handleAddToCompare,
  isExpanded,
  handleExpandCard,
  medicareQuoteType,
  msPlan,
  forSpouse,
  handleExpandMSCard,
  spousePlanId,
  isInGrid = false,
}) => {
  const [isFavourite, setIsFavourite] = useState(favourite)

  const onAddFavPlan = (planId: string) => {
    setIsFavourite(true)
  }

  const onRemoveFavPlan = (planId: string) => {
    setIsFavourite(false)
    handleRemoveFavourite && handleRemoveFavourite()
  }

  return (
    <FooterWrapper isInGrid={isInGrid}>
      <div className='left-side'>
        <SavePlanIconButton
          planId={id}
          planType={medicareQuoteType}
          saved={isFavourite}
          forSpouse={forSpouse}
          page='Dashboard Page'
          onAddSaved={onAddFavPlan}
          onRemoveSaved={onRemoveFavPlan}
          isPrimaryPlanSaved={isPrimarySaved}
          spousePlanId={spousePlanId}
        />
        {showCompareCheckbox && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={
                !!userActionStore.comparePlanCards.compareCardsData.find(
                  (iterator) => iterator.id === id
                )
                  ? true
                  : false
              }
              onChange={(e) => {
                handleAddToCompare(e)
                fireEvent(
                  !!userActionStore.comparePlanCards.compareCardsData.find(
                    (iterator) => iterator.id === id
                  )
                    ? 'addCardForComparison'
                    : 'removeAddedPlanForComparison',
                  e,
                  {
                    description:
                      !!userActionStore.comparePlanCards.compareCardsData.find(
                        (iterator) => iterator.id === id
                      )
                        ? 'Add Card for Comparison'
                        : 'Remove Card from Comparison',
                    planId: planId,
                    planType: planType,
                    planName: planName,
                  }
                )
              }}
              size='small'
            />
            <span style={{ whiteSpace: 'nowrap' }}>Add to Compare</span>
          </div>
        )}
      </div>
      <div>
        <Button
          variant='text'
          color='primary'
          fontSize='14px'
          endIcon={
            isExpanded || planName === customerStore.searchPlan ? (
              <ArrowUp style={{ fontSize: '24px' }} />
            ) : (
              <ArrowDown style={{ fontSize: '24px' }} />
            )
          }
          description={
            isExpanded || planName === customerStore.searchPlan
              ? 'View Less Details'
              : 'View More Details'
          }
          onClick={(e) => {
            if (handleExpandCard) {
              handleExpandCard(planName)
            } else if (msPlan && handleExpandMSCard) handleExpandMSCard(msPlan)

            fireEvent(
              isExpanded || planName === customerStore.searchPlan
                ? 'viewLessCardDetails'
                : 'viewMoreCardDetails',
              e,
              {
                value:
                  !isExpanded || planName !== customerStore.searchPlan
                    ? 'expanding'
                    : 'collapsing',
                planId: planId,
                planType: planType,
                description:
                  isExpanded || planName === customerStore.searchPlan
                    ? 'View Less Card Details'
                    : 'View More Card Details',
              }
            )
          }}
        >
          <>
            View{' '}
            {isExpanded || planName === customerStore.searchPlan
              ? 'less'
              : 'more'}{' '}
            details
          </>
        </Button>
      </div>
    </FooterWrapper>
  )
}

export default PlanCardFooter
