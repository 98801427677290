import { ActionContext } from '../../cra'
import AddedDrugsCard from './AddedDrugsCard'
import { AiOutlinePaperClip } from 'react-icons/ai'
import ApiManager from '../../api/ApiManager'
import BouncingDotsLoader from '../common/Loading/BouncingDotsLoader'
import Button from '../common/Button/Button.component'
import DisclosureStep from './StepperComponents/Prescriptions/DisclosureStep'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Icon from '../common/Icon/Icon.component'
import MillimanStepper from '../ProfilePage/Modals/MillimanStepper/MillimanStepper'
import ModalComponent from '../common/Modal/Modal.component'
import OverlayLoader from '../common/OverlayLoader/OverlayLoader'
import { PrescriptionDrug } from '../../interface/prescriptionDrug/PrescriptionDrug'
import QuotesRefreshProvider from '../../context/QuotesRefreshContext'
import React from 'react'
import RefreshDrugCostModal from '../DashboardPage/Modals/RefreshDrugCostModal'
import SearchPrompt from './SearchPrompt.Component'
import SectionRow from '../common/SectionRow'
import SecureComponent from '../../pages/common/SecureComponent'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { withRouter } from '../common/WithRouter/WithRouter.component'
interface AddPrescriptionProps {
  handleNext?: () => void
  isSpouse?: boolean
  redirect?: boolean
  pathContext?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const UpperDiv = styled.div`
  margin-bottom: 20px;
`

const AddedDrugsWrapper = styled.div`
  border-top: 1px solid ${theme.colors.borderColor};
  padding-top: 20px;
`

const AddedDrugsHeader = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 14px;
`

const DrugListWrapper = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -3.5rem;
  margin-bottom: 0.5rem;
  padding-right: 22px;
`

const AddPrescription: React.FC<AddPrescriptionProps> = ({
  handleNext,
  isSpouse,
  redirect = true,
  pathContext = 'Profile',
}) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [addDrugLoading, setAddDrugLoading] = React.useState<boolean>(false)
  const [deleteDrugLoading, setDeleteDrugLoading] =
    React.useState<boolean>(false)
  const [isEditDrug, setIsEditDrug] = React.useState(false)
  const [addPrescriptionState, setAddPrescriptionState] = React.useState<{
    AddedDrugs: PrescriptionDrug[]
    PrescriptionDrug: any[]
    addingDrugs: boolean
  }>({
    AddedDrugs: [],
    PrescriptionDrug: [],
    addingDrugs: false,
  })
  const [forSpouse, setForSpouse] = React.useState(
    isUndefined(isSpouse) ? customerStore.forSpouse : isSpouse
  )

  const [isDrugCostsToBeRefreshed, setIsDrugCostsToBeRefreshed] =
    React.useState<boolean>(false)

  const [isDrugCostsRefreshToBeDisplayed, setIsDrugCostsRefreshToBeDisplayed] =
    React.useState<boolean>(false)

  const [isOverlayLoading, setIsOverlayLoading] = React.useState<boolean>(false)

  // const [millimanModalOpen, setMillimanModalOpen] =
  //   React.useState<boolean>(false)

  const { trackCurrentPage } = React.useContext(ActionContext)

  React.useEffect(() => {
    trackCurrentPage(
      `${pathContext === 'infoSteps' ? 'Info Steps -' : 'Profile -'} ${
        customerStore.forSpouse
          ? 'Spouse/Partner’s Prescriptions'
          : 'Prescriptions'
      }`
    )
    setForSpouse(customerStore.forSpouse)
  }, [customerStore.forSpouse])

  React.useEffect(() => {
    if (addPrescriptionState.AddedDrugs.length > 0) {
      const currentMonth = new Date().getMonth() + 1
      addPrescriptionState.AddedDrugs.forEach((drug) => {
        if (new Date(drug.drugCostUpdatedAt).getMonth() + 1 < currentMonth) {
          setIsDrugCostsToBeRefreshed(true)
          return
        }
      })
    }
  }, [addPrescriptionState])

  const handleOkRefreshDrugCost = () => {
    setIsDrugCostsToBeRefreshed(false)
    setIsOverlayLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REFRESH_DRUG_COST.USECASE,
      HttpMethods.GET_METHOD,
      { 'for-spouse': forSpouse }
    )
      .then(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Drug cost refreshed successfully',
          snackbarType: 'success',
        })
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Unable to refresh drug cost',
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setIsOverlayLoading(false)
      })
  }

  return (
    <Container>
      {isDrugCostsRefreshToBeDisplayed && (
        <ButtonWrapper>
          <div>
            <Button
              fontSize='14px'
              width='100px'
              height='30px'
              variant='contained'
              bold={false}
              onClick={() => {
                setIsDrugCostsToBeRefreshed(true)
                setIsDrugCostsRefreshToBeDisplayed(false)
              }}
            >
              Refresh
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              color: 'red',
              fontSize: '12px',
              fontWeight: '700',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            * Refresh your prescriptions to view the latest costs
          </div>
        </ButtonWrapper>
      )}
      {isOverlayLoading && <OverlayLoader />}
      {isDrugCostsToBeRefreshed && (
        <ModalComponent
          setOpen={() => {}}
          description='Refresh drug cost prompt'
        >
          <RefreshDrugCostModal
            onConfirm={handleOkRefreshDrugCost}
            onReject={() => {
              setIsDrugCostsToBeRefreshed(false)
              setIsDrugCostsRefreshToBeDisplayed(true)
            }}
          ></RefreshDrugCostModal>
        </ModalComponent>
      )}

      <UpperDiv>
        <QuotesRefreshProvider>
          <SearchPrompt
            addPrescriptionState={addPrescriptionState}
            setAddPrescriptionState={setAddPrescriptionState}
            handleNext={handleNext}
            forSpouse={forSpouse}
            loading={loading}
            setLoading={setLoading}
            redirect={redirect}
            addDrugLoading={addDrugLoading}
            setAddDrugLoading={setAddDrugLoading}
            setIsEditDrug={setIsEditDrug}
            isEditDrug={isEditDrug}
          />

          <AddedDrugsWrapper>
            {loading ? (
              <BouncingDotsLoader />
            ) : (
              <>
                <AddedDrugsHeader>
                  <Icon
                    childComponent={<AiOutlinePaperClip />}
                    fontSize='18px'
                  />
                  Added Drugs: {addPrescriptionState.AddedDrugs.length}
                </AddedDrugsHeader>

                {addDrugLoading || deleteDrugLoading ? (
                  <BouncingDotsLoader />
                ) : (
                  <DrugListWrapper>
                    {addPrescriptionState.AddedDrugs.map((item, key) => (
                      <AddedDrugsCard
                        greyOut={isEditDrug}
                        key={key}
                        addPrescriptionState={addPrescriptionState}
                        setAddPrescriptionState={setAddPrescriptionState}
                        data={item}
                        setDeleteDrugLoading={setDeleteDrugLoading}
                        setIsEditDrug={setIsEditDrug}
                        forSpouse={forSpouse}
                      />
                    ))}
                  </DrugListWrapper>
                )}
              </>
            )}
          </AddedDrugsWrapper>
        </QuotesRefreshProvider>
      </UpperDiv>
      {/* {customerStore.getIsMEnabled() &&
        customerStore.getIsSsnProvided() === false &&
        addPrescriptionState.AddedDrugs.length === 0 && (
          <div>
            <div>
              <h2>OR</h2>
              <p>
                Look up list of prescriptions using my Social Security Number
                (SSN)
              </p>
              <Button
                color='primary'
                variant='contained'
                width='220px'
                onClick={() => {
                  setMillimanModalOpen(true)
                }}
              >
                Lookup my prescriptions
              </Button>
            </div>
          </div>
        )} */}
      {/* {millimanModalOpen && (
        <MillimanStepper
          setModalOpen={setMillimanModalOpen}
          setAddPrescriptionState={setAddPrescriptionState}
        />
      )} */}
    </Container>
  )
}

export default SecureComponent(observer(withRouter(AddPrescription)))
