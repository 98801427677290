import {
  capitalizeFirstCharacter,
  getApiErrorMessage,
  toSentenceCase,
} from '../../utils/StringUtils'

import ApiManager from '../../api/ApiManager'
import Card from '../common/Card/Card.component'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { PrescriptionDrugFrequencyEnum } from '../../enums/PrescriptionDrugFrequencyEnum'
import { QuotesRefreshContext } from '../../context/QuotesRefreshContext'
import React from 'react'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { deletePrescription } from '../../service/prescriptions'
import { getPlanYear } from '../../utils/CommonUtils'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { pollQuotesPullStatus } from '../../utils/quotesPullStatus'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'

interface DrugCard {
  title: string
  dosage: string[]
  frequencyOptions: string[]
  quantity: number
  currentDosage?: {
    rxcui: string
    name: string
  }
  isEditing?: boolean
}

interface DrugDataProps {
  id: string
  name: string
  currentDosage: {
    rxcui: string
    name: string
  }
  availableDosages?: DrugDataProps['currentDosage'][]
  quantity: number
  frequency: string
  isSpouse: boolean
}

interface AddedDrugsCardInfoStepsProps {
  addPrescriptionState: {
    AddedDrugs: any[]
    PrescriptionDrug: any[]
    addingDrugs: boolean
  }
  setAddPrescriptionState: React.Dispatch<
    React.SetStateAction<{
      AddedDrugs: any[]
      PrescriptionDrug: any[]
      addingDrugs: boolean
    }>
  >
  data: any
  setDeleteDrugLoading?: (flag: boolean) => void
  setIsEditDrug?: (flag: boolean) => void
  greyOut?: boolean
  forSpouse?: boolean
}

interface ContainerProps {
  greyOut: boolean | undefined
}
const Container = styled.div<ContainerProps>`
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`
const Header = styled.div`
  max-width: 70%;
  padding-bottom: 10px;
  p {
    font-weight: 400;
    font-size: 12px;
  }
  h2 {
    font-size: 18px;
  }
`

const Body = styled.div`
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`

const SubHeadTitle = styled.p`
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
`

const SubHeadContent = styled.p`
  padding-top: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #515151;

  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
`

const Dose = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  padding-left: 30px;
  border-left: 1px solid #e5e5e5;

  @media screen and (max-width: 360px) {
    padding-left: 10px;
  }
`

const Frequency = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

const AddedDrugsCardInfoSteps: React.FC<AddedDrugsCardInfoStepsProps> = ({
  addPrescriptionState,
  setAddPrescriptionState,
  data,
  setDeleteDrugLoading = () => {},
  setIsEditDrug = () => {},
  greyOut,
  forSpouse,
}) => {
  const { setIsQuotesRefreshing } = React.useContext(QuotesRefreshContext)
  const deletePrescriptionDrug = (
    prescriptionDrugId: string
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (isEmpty(prescriptionDrugId)) {
        reject(new Error('Prescription drug id should not be empty'))
      } else {
        deletePrescription({
          $prescriptionDrugId: prescriptionDrugId,
          'for-spouse': data.isSpouse,
        })
          .then((response: any) => {
            if (response.status === 200) {
              if (!forSpouse) {
                medicareAdvantageFilterQuoteStore.clearStore()
                mPartDPlansFilterStore.clearStore()
                setIsQuotesRefreshing(true)
                pollQuotesPullStatus(getPlanYear())
              }
              resolve(true)
            } else throw response
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }

  const handleEdit = () => {
    setIsEditDrug(true)
    const tempAddedDrugs = addPrescriptionState.AddedDrugs
    const tempPrescriptionDrugs = addPrescriptionState.AddedDrugs.filter(
      (item) => item.id === data.id
    )

    const filteredPrescriptionDrug: DrugDataProps = tempPrescriptionDrugs[0]
    const prescriptionDrugForEditing: DrugCard =
      tempPrescriptionDrugs[0] as DrugCard
    prescriptionDrugForEditing.title = filteredPrescriptionDrug.name
    prescriptionDrugForEditing.dosage =
      filteredPrescriptionDrug.availableDosages
        ? filteredPrescriptionDrug.availableDosages.map((x) => x.name)
        : []
    prescriptionDrugForEditing.frequencyOptions = Object.values(
      PrescriptionDrugFrequencyEnum
    )
    prescriptionDrugForEditing.currentDosage =
      filteredPrescriptionDrug.currentDosage
    prescriptionDrugForEditing.isEditing = true
    setAddPrescriptionState({
      ...addPrescriptionState,
      AddedDrugs: tempAddedDrugs,
      addingDrugs: true,
      PrescriptionDrug: [prescriptionDrugForEditing],
    })
  }

  const handleDelete = () => {
    setDeleteDrugLoading(true)
    deletePrescriptionDrug(data.id)
      .then(() => {
        //TODO: Remove the try/catch
        try {
          medicareAdvantageFilterQuoteStore.removeDrugNameOnDeletionOfDrug(
            data.name
          )
          mPartDPlansFilterStore.removeDrugNameOnDeletionOfDrug(data.name)
        } catch (error) {
          console.log('Error in Delete: ', error)
        }
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_DELETE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        const drugListAfterDeletion = addPrescriptionState.AddedDrugs.filter(
          (item) => item.id !== data.id
        )
        if (
          drugListAfterDeletion.length === 0 &&
          customerStore.get().forSpouse === false
        ) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.DELETE_INFO_STEPS.USECASE,
            HttpMethods.DELETE_METHOD,
            {
              $infoStep: 'prescription',
            }
          )
            .then((res) => {
              if (res.status === 200) {
                snackbarStore.set({
                  snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                  snackbarOpen: true,
                  snackbarType: SnackbarTypes.SUCCESS,
                })
                customerStore.setInfoSteps([
                  ...customerStore.infoSteps.filter((iterator: string) => {
                    if (iterator !== 'prescription') {
                      return iterator
                    }
                    return false
                  }),
                ])
              } else throw res
            })
            .catch((err) => {
              snackbarStore.set({
                snackbarMessage: 'Something went wrong',
                snackbarOpen: true,
                snackbarType: 'error',
              })
            })
        }
        setAddPrescriptionState({
          ...addPrescriptionState,
          AddedDrugs: drugListAfterDeletion,
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('delete prescription drug'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => setDeleteDrugLoading(false))
  }
  return (
    <Card
      cardType='edit'
      deleteCard={handleDelete}
      editCard={handleEdit}
      borderColor={'#E5E5E5'}
      greyOut={greyOut || addPrescriptionState.addingDrugs}
    >
      <Container greyOut={greyOut}>
        <Header>
          <h2>{capitalizeFirstCharacter(data.name)}</h2>
          <p style={{ color: '#515151' }}>Brand Drug Name</p>
        </Header>
        <Body>
          <Frequency>
            <SubHeadTitle>Frequency</SubHeadTitle>
            <SubHeadContent>
              {data.currentDosage.qty} every{' '}
              {data.currentDosage.frequency.toString() ===
              PrescriptionDrugFrequencyEnum.EveryMonth
                ? 'month'
                : data.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EveryTwoMonths
                ? 'two months'
                : data.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EveryThreeMonths
                ? 'three months'
                : data.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EverySixMonths
                ? 'six months'
                : 'twelve months'}
            </SubHeadContent>
          </Frequency>
          <Dose>
            <SubHeadTitle>Dose</SubHeadTitle>
            <SubHeadContent>
              {toSentenceCase(data.currentDosage.name)}
            </SubHeadContent>
          </Dose>
        </Body>
      </Container>
    </Card>
  )
}

export default AddedDrugsCardInfoSteps
