import AddPrescriptionComponent from '../../InfoStepsPage/AddPrescription.component'
import Button from '../../common/Button/Button.component'
import MillimanStepper from '../Modals/MillimanStepper/MillimanStepper'
import { PrescriptionCountEnum } from '../../../enums/PrescriptionCountEnum'
import { PrescriptionDrug } from '../../../interface/prescriptionDrug/PrescriptionDrug'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import SectionRow from '../../common/SectionRow'
import Select from '../../common/Select/Select.component'
import SkeletonCard from '../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { getPrescriptions } from '../../../service/prescriptions'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

interface PrescriptionComponentProps {
  isSpouse: boolean
}

const showSsnRadioOptions = [
  'Look up list of prescriptions using my Social Security Number (SSN)',
  'I want to add my prescriptions in manually',
]

const PrescriptionComponent: React.FC<PrescriptionComponentProps> = ({
  isSpouse = false,
}) => {
  const [loading, setLoading] = React.useState(true)
  const [showAddDrugOption, setshowAddDrugOption] = React.useState(false)
  const [showSsnStep, setShowSsnStep] = React.useState<boolean | null>(null)
  const [showDisclosureOption, setShowDisclosureOption] = React.useState<
    boolean | null
  >(null)
  const [prescriptionCount, setPrescriptionCount] =
    React.useState<PrescriptionCountEnum | null>(null)
  const [showMillimanOption, setShowMillimanOption] = React.useState(false)

  const getPrescriptionCountRange = (
    prescriptionCount: PrescriptionCountEnum | null
  ) => {
    switch (prescriptionCount) {
      case PrescriptionCountEnum.LESS_THAN_THREE:
        return [0, 2]
      case PrescriptionCountEnum.THREE_TO_FIVE:
        return [3, 5]
      case PrescriptionCountEnum.SIX_TO_TEN:
        return [6, 10]
      case PrescriptionCountEnum.ELEVEN_PLUS:
        return [11, 100]
      default:
        return [0, 0]
    }
  }

  const fetchPrescriptionDrugs = () => {
    setLoading(true)

    getPrescriptions({ 'for-spouse': isSpouse })
      .then((response: any) => {
        const data: PrescriptionDrug[] = response.data.data
        if (data.length > 0) {
          setshowAddDrugOption(true)
        }
      })
      .catch((err: any) => {
        let message = err.data
          ? err.data.message
            ? err.data.message
            : err.data
          : getApiErrorMessage('fetch prescription drug')

        if (/No prescription drugs are available/.test(message)) return
        else {
          snackbarStore.set({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    if (!isSpouse) {
      fetchPrescriptionDrugs()
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return (
      <Container>
        <SectionRow>
          <SkeletonCard quantity={10} />
        </SectionRow>
      </Container>
    )
  }

  if (showAddDrugOption || isSpouse) {
    return (
      <Container>
        <AddPrescriptionComponent redirect={false} isSpouse={isSpouse} />
      </Container>
    )
  }

  if (showMillimanOption) {
    return (
      <Container>
        <SectionRow>
          <MillimanStepper setShowAddDrugComponent={setshowAddDrugOption} />
        </SectionRow>
      </Container>
    )
  }

  return (
    <Container>
      <SectionRow row>
        <Select
          bottomPadding={false}
          label='Please select your number of prescriptions below.'
          options={[
            PrescriptionCountEnum.LESS_THAN_THREE,
            PrescriptionCountEnum.THREE_TO_FIVE,
            PrescriptionCountEnum.SIX_TO_TEN,
            PrescriptionCountEnum.ELEVEN_PLUS,
          ]}
          value={prescriptionCount}
          onChange={(e) => {
            const selectedOption = e.target.value as PrescriptionCountEnum
            setPrescriptionCount(selectedOption)
            if (
              getPrescriptionCountRange(selectedOption)[0] >=
              customerStore.getMMinimumDrugCount()
            ) {
              setShowSsnStep(true)
            } else {
              setShowSsnStep(false)
            }
          }}
        />
      </SectionRow>
      {showSsnStep && (
        <SectionRow>
          <RadioGroup
            bottomPadding={false}
            radioButtonStyle={{
              borderRadius: '4px',
              background: theme.colors.backgroundColorContainer,
            }}
            label='Please select your preferred method below.'
            radioButtons={showSsnRadioOptions}
            onChange={(e) => {
              if (e.target.value === showSsnRadioOptions[0]) {
                setShowDisclosureOption(true)
              } else {
                setShowDisclosureOption(false)
              }
            }}
            value={
              showDisclosureOption === true
                ? showSsnRadioOptions[0]
                : showDisclosureOption === false
                ? showSsnRadioOptions[1]
                : ''
            }
            radioGroupName='showSsnOptionQuestion'
          />
        </SectionRow>
      )}

      <SectionRow row style={{ paddingTop: '20px' }}>
        <Button
          width='100%'
          disabled={
            showSsnStep === null ||
            (showSsnStep === true && showDisclosureOption === null)
          }
          color='secondary'
          variant='contained'
          onClick={() => {
            if (showDisclosureOption === true) {
              setShowMillimanOption(true)
            } else {
              setshowAddDrugOption(true)
            }
          }}
        >
          Save
        </Button>
      </SectionRow>
    </Container>
  )
}

export default PrescriptionComponent
