import { action, computed, makeAutoObservable, observable } from 'mobx'
import { create, persist } from '../mobx-persist'

import { County } from '../interface/CountyInterface'
import { MedicareQuotesType } from '../enums/MedicareQuoteTypeEnum'
import { RiderTypesEnum } from '../enums/RiderTypesEnum'

type PlanTypes = MedicareQuotesType

type zipCache = {
  code: string
  county?: County
  city?: string
  state?: string
  optCounties?: County[]
}

interface compareCardsDataI {
  id: string
  name: string
  premium: number
  carrier: string
  enrollRequestStatus: boolean
  planType: MedicareQuotesType
  medicareSupplementPlanType?: string | undefined
  ridersApplied?: string[] | undefined
  sunfirePlanId?: number
  rating?: number
  moop?: number
}

export interface AddToCompareI {
  planType: PlanTypes | ''
  compareCardsData: compareCardsDataI[]
}

interface EnrollmentRequestPlanType {
  planId: string
  planType: MedicareQuotesType | null
}

const hydrate = create({
  storage: sessionStorage,
  jsonify: true,
})

class UserActionStore {
  @persist('object') @observable comparePlanCards: AddToCompareI = {
    planType: '',
    compareCardsData: [],
  }

  @persist('list') @observable zipCodeCache: zipCache[] = []

  @persist('object')
  @observable
  enrollmentRequestPlan: EnrollmentRequestPlanType = {
    planId: '',
    planType: null,
  }

  @persist('map')
  @observable
  ridersApplied: Map<string, string[]> = new Map<string, string[]>()

  @persist('map') @observable stdPlanApplied: Map<string, string> = new Map<
    string,
    string
  >()

  @persist @observable isloading = false

  @action setEnrollmentRequestedPlan(
    enrollmentRequestedPlan: EnrollmentRequestPlanType
  ) {
    this.enrollmentRequestPlan = enrollmentRequestedPlan
  }

  @computed
  getEnrollmentRequestPlan() {
    return this.enrollmentRequestPlan
  }

  @action addCard(planType: PlanTypes | '', cardData: compareCardsDataI) {
    if (planType === this.comparePlanCards.planType) {
      this.comparePlanCards.planType = planType ? planType : ''
      this.comparePlanCards.compareCardsData.push(cardData)
    } else {
      this.comparePlanCards.planType = planType ? planType : ''
      this.comparePlanCards.compareCardsData = []
      this.comparePlanCards.compareCardsData.push(cardData)
    }
  }

  @action removeCard(id: string) {
    if (this.comparePlanCards.compareCardsData.length >= 1) {
      const filteredCardsData = this.comparePlanCards.compareCardsData.filter(
        (iterator) => iterator.id !== id
      )
      this.comparePlanCards.compareCardsData = filteredCardsData
    }

    if (this.comparePlanCards.compareCardsData.length === 0) {
      this.resetComparePlanCards()
    }
  }

  @action updateCard(planType: PlanTypes | '', cardData: compareCardsDataI) {
    const index = this.comparePlanCards.compareCardsData.findIndex(
      (x) => x.id === cardData.id
    )
    if (index < 0) return
    this.comparePlanCards.planType = planType ? planType : ''
    this.comparePlanCards.compareCardsData[index] = cardData
  }

  @computed getComparePlanCards() {
    return this.comparePlanCards
  }

  @action resetComparePlanCards() {
    this.comparePlanCards.planType = ''
    this.comparePlanCards.compareCardsData = []
  }

  @action reset() {
    this.resetComparePlanCards()
    this.zipCodeCache = []
    this.enrollmentRequestPlan = {
      planId: '',
      planType: null,
    }
    this.ridersApplied.clear()
    this.stdPlanApplied.clear()
  }

  @action setZipCodeCache(props: zipCache) {
    const postalCodeExists = this.zipCodeCache.find(
      (iterator) => iterator.code === props.code
    )

    if (!postalCodeExists) {
      this.zipCodeCache.push({ ...props })
    }

    if (postalCodeExists) {
      const filteredCache = this.zipCodeCache.filter(
        (iterator) => iterator.code !== props.code
      )
      const newZipEntry: zipCache = {
        ...postalCodeExists,
        code: props.code,
      }
      if (props.county) newZipEntry.county = props.county
      if (props.optCounties) newZipEntry.optCounties = props.optCounties
      if (props.state) newZipEntry.state = props.state
      if (props.city) newZipEntry.city = props.city

      filteredCache.push(newZipEntry)

      this.zipCodeCache = [...filteredCache]
    }
  }

  getRidersApplied(planId: string) {
    return this.ridersApplied.get(planId) || []
  }

  @computed getRidersAppliedWithFullNames(planId: string) {
    const riders = this.ridersApplied.get(planId) || []
    return riders.map((rider) => RiderTypesEnum[rider])
  }

  @action setRidersApplied(planId: string, ridersApplied: string[]) {
    this.ridersApplied.set(planId, ridersApplied)
  }

  @action clearRidersApplied() {
    this.ridersApplied.clear()
  }

  getStdPlanApplied(planId: string) {
    return this.stdPlanApplied.get(planId)
  }

  @action setStdPlanApplied(planId: string, stdPlanApplied: string) {
    this.stdPlanApplied.set(planId, stdPlanApplied)
  }

  @action clearStdPlanApplied() {
    this.stdPlanApplied.clear()
  }

  @action setIsLoading(isloading: boolean) {
    this.isloading = isloading
  }

  @computed getIsLoading() {
    return this.isloading
  }

  constructor() {
    makeAutoObservable(this)
    Promise.all([hydrate('userActionStore', this)])
  }
}

const userActionStore = new UserActionStore()

export default userActionStore
