import { Button, SelectChangeEvent } from '@mui/material'
import React, { useEffect } from 'react'

import ApiManager from '../../../api/ApiManager'
import { Checkbox } from '@mui/material'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import { MdLocationPin } from 'react-icons/md'
import PaginationConstants from '../../../constants/PaginationConstants'
import { PharmacyObj } from '../../../interface/PharmacyInterface'
import Select from '../../common/Select/Select.component'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../../constants/UrlConstants'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import snackbarStore from '../../../datastore/SnackbarStore'
import { sortBy } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  handleSearchClick?: (item: object[]) => void
  radius: number
  zipCode: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPharmacyList: React.Dispatch<React.SetStateAction<PharmacyObj[]>>
  radiusOptions: string[]
  selectNextRadiusOption: (selectedRadiusDisplayValue: string) => void
  addedPharmacyList: PharmacyObj[]
  radiusDisplayValue?: string
  handleRadiusChange?: (e: SelectChangeEvent<string>) => void
  forSpouse?: boolean
}

const Container = styled.div`
  width: 100%;
  position: relative;

  h3 {
    color: #272f69;
    padding-bottom: 16px;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.white};
  padding: 20px 0px;
  position: sticky;
  bottom: -40px;
  left: 0;

  @media screen and (max-width: 500px) {
    bottom: -20px;
  }
`
const SearchItem = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  text-align: left;
  padding: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  display: flex;
  &:hover {
    background-color: ${theme.colors.contrastPrimary};
  }
`

const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${theme.colors.textGrey};
  }
`

const PharmacyWrapper = styled.div`
  max-width: 70%;
`

let items: PharmacyObj[] = []
let page: number = PaginationConstants.DEFAULT_PAGE
let data: any[] = []

const PharmacyListSearch: React.FC<ModelProps> = ({
  handleSearchClick,
  radius,
  zipCode,
  setOpen,
  setPharmacyList,
  radiusOptions,
  selectNextRadiusOption,
  addedPharmacyList,
  radiusDisplayValue,
  handleRadiusChange,
  forSpouse,
}) => {
  const [searchWord, setSearchWord] = React.useState<string>('')
  const [displayData, setDisplayData] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [count, setCount] = React.useState<number>(5)
  const [pharmacies, setPharmacies] = React.useState<any[]>([])

  const handleTextChange = (word: string) => {
    if (word === '') {
      setDisplayData(data)
    } else {
      const newFilter = data
        ? data.filter((value) => {
            return value.title.toLowerCase().startsWith(word.toLowerCase())
          })
        : []
      setDisplayData(newFilter)
    }
  }

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    let word = ''
    if (e.target.value.length >= 0) {
      word = e.target.value
      setSearchWord(e.target.value)
    }
    handleTextChange(word)
  }

  const getPharmacyListFromSF = (page: number, newRadius?: number) => {
    setLoading(true)
    if (page === 1) items = []
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PHARMACY_WITH_ZIP_SF.USECASE,
      HttpMethods.POST_METHOD,
      {
        zip: zipCode,
        radius: newRadius ? newRadius : radius,
        paginationDetail: {
          page: page,
          size: PaginationConstants.DEFAULT_PHARMACY_LIST_SIZE,
        },
        isSpouse: forSpouse,
        isMailOrderPharmacy: true,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          const newlyPulledPharmacies: PharmacyObj[] = []
          res.data.data.forEach((iterator: any) => {
            if (iterator.name === '') {
              return
            } else {
              const tempData: PharmacyObj = iterator
              tempData.pageNumber = page
              newlyPulledPharmacies.push(tempData)
            }
          })

          const pharmaciesSortedByDistance = sortBy(
            [...items, ...newlyPulledPharmacies],
            function (o: any) {
              return o.distance
            }
          )
          items = pharmaciesSortedByDistance

          let listOfPharmacies = items.map((item: any, index: any) => {
            return {
              index,
              id: item.id,
              title: item.name,
              subtitle:
                item.defaultMailOrder === false
                  ? `${item.address1}${
                      item.address2 ? ', ' + item.address2 : ''
                    }, ${item.city}, ${item.state}, ${item.zip}`
                  : `Add both mail-order and retail pharmacies to find the lowest cost.`,

              distance: item.distance,
              page: item.pageNumber,
              isChecked: false,
              isSaved: false,
              defaultMailOrder: item.defaultMailOrder,
            }
          })
          setCount(
            Math.ceil(
              res.data.totalCount /
                PaginationConstants.DEFAULT_PHARMACY_LIST_SIZE
            )
          )
          data = listOfPharmacies
          setPharmacyList(items)
          setDisplayData(listOfPharmacies)
          setLoading(false)
          if (searchWord !== '') handleTextChange(searchWord)
        } else {
          throw res
        }
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage('fetch pharmacy with zip'),
          snackbarType: SnackbarTypes.ERROR,
        })
        setLoading(false)
        setOpen(false)
      })
  }

  useEffect(() => {
    getPharmacyListFromSF(PaginationConstants.DEFAULT_PAGE)
    return () => {
      items = []
      page = PaginationConstants.DEFAULT_PAGE
      data = []
    }
  }, [])

  useEffect(() => {
    if (displayData.length > 0 && addedPharmacyList.length !== 0) {
      displayData.forEach((item) => {
        const foundPharmacy = addedPharmacyList.find(
          (pharmacy: any) => item.id === pharmacy.pharmacyId
        )
        item.isSaved = !!foundPharmacy
      })
    }
  }, [displayData, addedPharmacyList])

  const handleLoadMore = () => {
    if (page === 1 && isNaN(count)) {
      let selectedRadiusDisplayValue = radius + ' miles'
      for (let cntr = 0; cntr < radiusOptions.length - 1; cntr++) {
        if (
          selectedRadiusDisplayValue === radiusOptions[cntr].toString() &&
          cntr + 1 < radiusOptions.length
        ) {
          selectedRadiusDisplayValue = radiusOptions[cntr + 1].toString()
          radius = +selectedRadiusDisplayValue.replace(' miles', '')
          selectNextRadiusOption(selectedRadiusDisplayValue)
          break
        }
      }
    } else page += 1
  }

  const handlePharmacySelection = (item: any) => {
    if (pharmacies.includes(item)) {
      setPharmacies((prevPharmacies) =>
        prevPharmacies.filter((pharmacy) => pharmacy.index !== item.index)
      )
      item.isChecked = false
    } else {
      setPharmacies([...pharmacies, item])
      item.isChecked = true
    }
  }

  const handleAddPharmacy = async () => {
    if (pharmacies.length > 0) {
      if (handleSearchClick) {
        await handleSearchClick(pharmacies)
      }
    }
  }

  return (
    <Container>
      <h3>
        Choose a pharmacy
        <div
          style={{
            color: '#BA0000',
            fontSize: '12px',
            fontStyle: 'italic',
            fontWeight: '600',
          }}
        >
          Add up to 3 pharmacies
        </div>
      </h3>

      <SelectionWrapper>
        <p>Search within: </p>&nbsp;&nbsp;
        <Select
          bottomPadding={false}
          formLabel=''
          options={radiusOptions}
          height='30px'
          value={radiusDisplayValue}
          disabled={loading}
          onChange={(e) => {
            handleRadiusChange && handleRadiusChange(e)
            getPharmacyListFromSF(
              PaginationConstants.DEFAULT_PAGE,
              parseInt(e.target.value)
            )
          }}
        />
      </SelectionWrapper>

      <div style={{ width: '100%' }}>
        <InputField
          width='100%'
          onChange={handleFilter}
          placeholder='Start typing your pharmacy name...'
          additionalTextPaddingLeft='15px'
          disabled={loading}
        />
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '60vh',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              color: theme.colors.primary,
              paddingBottom: '5px',
              fontWeight: '700',
            }}
          >
            Please wait while we fetch the pharmacies nearby
          </span>
          <ThreeDots color='#222C69' height={80} width={80} />
        </div>
      ) : (
        <div
          style={{
            paddingTop: '20px',
          }}
        >
          {displayData.length > 0 ? (
            displayData.map((item, key) => {
              return (
                <SearchItem key={key}>
                  <div>
                    <Checkbox
                      onChange={() => handlePharmacySelection(item)}
                      disabled={
                        (addedPharmacyList.length + pharmacies.length >= 3 &&
                          !pharmacies.includes(item)) ||
                        item.isSaved
                      }
                      checked={item.isChecked || item.isSaved}
                      style={{ width: 'auto' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <PharmacyWrapper>
                      <p
                        style={{
                          fontSize: '14px',
                          color: theme.colors.textDark,
                          paddingBottom: '5px',
                          fontWeight: '700',
                          maxWidth: '300px',
                        }}
                      >
                        {item.title}
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          color: theme.colors.textGrey,
                          maxWidth: '300px',
                        }}
                      >
                        {item.defaultMailOrder === true
                          ? `Add both mail-order and retail pharmacies to find the lowest cost.`
                          : item?.subtitle}
                      </p>
                    </PharmacyWrapper>
                    <div
                      style={{
                        fontSize: '12px',
                        color: theme.colors.textDark,
                        textAlign: 'right',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <MdLocationPin
                        style={{
                          fontSize: '14px',
                        }}
                      />
                      {' ' + item?.distance.toFixed(2) + ' mi'}
                    </div>
                  </div>
                </SearchItem>
              )
            })
          ) : (
            <SearchItem>
              <p
                style={{
                  fontSize: '14px',
                  color: theme.colors.textDark,
                  paddingBottom: '5px',
                }}
              >
                No pharmacies in this radius. Please expand your search radius
                to load more pharmacies.
              </p>
            </SearchItem>
          )}
        </div>
      )}
      {!loading && (
        <PaginationWrapper>
          <Button
            color='primary'
            variant='contained'
            disabled={pharmacies.length === 0}
            onClick={handleAddPharmacy}
            style={{ borderRadius: '20px', textTransform: 'none' }}
          >
            Add Pharmacy
          </Button>
        </PaginationWrapper>
      )}
    </Container>
  )
}

export default PharmacyListSearch
