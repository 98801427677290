import React, { useEffect, useState } from 'react'
import customerStore, { CustomerDTO } from '../../datastore/CustomerStore'
import {
  getCustomerDetails,
  updateCustomerDetails,
} from '../../service/customer'
import { isEmpty, isUndefined } from 'lodash'

import Button from '../common/Button/Button.component'
import { County } from '../../interface/CountyInterface'
import Select from '../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import { ThreeDots } from 'react-loader-spinner'
import ZipUtils from '../../utils/ZipUtils'
import { getApiErrorMessage } from '../../utils/StringUtils'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'

interface AddDetailsProps {
  initiateTimer: () => void
  tabNumber: number
}

interface HealthQuestionProps {
  id: string
  inputType: 'RADIO' | 'CHECKBOX'
  isRequired: boolean
  options: { option: string }[]
  question: string
  response: string[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: auto;

  @media screen and (max-width: 750px) {
    width: 70vw;
  }

  @media screen and (max-width: 1200px) and (min-width: 750px) {
    width: 40vw;
  }
`

const HeadingContent = styled.h3`
  color: ${theme.colors.primary};
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 1rem;
`

const ButtonWrapper = styled.div`
  padding: 25px 0px 50px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: 20px 0px 5px 0px;
  font-weight: 600;
`

const RadioSectionWrapper = styled.div`
  padding-right: 220px;
  width: 145%;

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  .MuiFormGroup-root {
    @media screen and (max-width: 750px) {
      width: fit-content;
    }
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const AddMNSPlanDetails: React.FC<AddDetailsProps> = ({
  initiateTimer,
  tabNumber,
}) => {
  const [optCounties, setOptCounties] = React.useState<County[]>([])
  const [addrCounty, setAddrCounty] = React.useState<County>({} as County)
  const [addrCity, setAddrCity] = React.useState('')
  const [addrState, setAddrState] = React.useState('')
  const [addrStreet, setAddrStreet] = React.useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true)
  const [state, setState] = useState({
    addrCountyError: false,
    dobError: false,
    dobHelperText: '',
    spouseDobError: false,
    spouseDobHelperText: '',
    loading: false,
    buttonLoading: false,
  })

  const GetCountiesCityAndStateFromZip = (zipCode: string) => {
    const zipCodeCache = userActionStore.zipCodeCache.find((iterator) => {
      if (iterator.code === zipCode) return iterator
    })

    if (!zipCodeCache) {
      ZipUtils.GetCountyCityAndStateForZip(zipCode)
        .then((response: any) => {
          if (!isEmpty(response.city)) {
            if (zipCode !== customerStore.postalCode) {
              setAddrCity(response.city)
              userActionStore.setZipCodeCache({
                code: zipCode,
                city: response.city,
              })
            } else {
              userActionStore.setZipCodeCache({
                code: zipCode,
                city: isEmpty(addrCity) ? customerStore.city : addrCity,
              })
            }
          } else {
            if (isEmpty(addrCity)) setAddrCity('')
          }
          if (!isEmpty(response.state)) {
            if (zipCode !== customerStore.postalCode || isEmpty(addrState))
              setAddrState(response.state)
            userActionStore.setZipCodeCache({
              code: zipCode,
              city: addrCity,
              state: response.state,
            })
          } else {
            if (isEmpty(addrState)) setAddrState('')
          }
          if (!isEmpty(response.counties)) {
            if (zipCode !== customerStore.postalCode || isEmpty(optCounties))
              setOptCounties(response.counties)
            let county
            if (
              zipCode !== customerStore.postalCode ||
              isEmpty(customerStore.county) ||
              isEmpty(customerStore.countyFips)
            ) {
              county = response.counties[0]
              // setAddrCounty(county)
            } else {
              county = {
                countyName: customerStore.county,
                countyFips: customerStore.countyFips,
              }
              // setAddrCounty(county)
            }
            // resetInputErrors()
            userActionStore.setZipCodeCache({
              code: zipCode,
              city: addrCity,
              state: addrState,
              county: county,
              optCounties: response.counties,
            })
          } else {
            setOptCounties([])
            setAddrCounty({} as County)
            setState({ ...state, addrCountyError: true })
          }
        })
        .catch((error: any) => {
          snackbarStore.set({
            snackbarMessage:
              error.data &&
              error.data.length > 0 &&
              !isEmpty(error.data[0]) &&
              error.data[0].reason
                ? error.data[0].reason
                : StringConstants.UI_ERROR_MSG_ON_COUNTY_NOT_RETRIEVED_FROM_ZIP,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
          setAddrCity('')
          setAddrState('')
          setAddrCounty({} as County)
          setAddrStreet('')
          setOptCounties([])
          setState({
            ...state,
            addrCountyError: false,
          })
        })
    } else {
      setOptCounties(zipCodeCache.optCounties || [])
      setAddrState(zipCodeCache.state || '')
      setAddrCounty(zipCodeCache.county || ({} as County))
      if (isEmpty(addrCity)) setAddrCity(zipCodeCache.city || '')
      // resetInputErrors()
    }

    setIsPageLoading(false)
  }

  const submitResponse = (data: Object) => {
    setIsLoading(true)
    updateCustomerDetails(data)
      .then((response) => {
        getCustomerDetails().then((updatedCustomerDetails: CustomerDTO) => {
          customerStore.set(updatedCustomerDetails)
          customerStore.setQuotePulledStatusMA(true)
          customerStore.setQuotePulledStatusMPartD(true)
          initiateTimer()
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.SUCCESS,
          })
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleSubmit = () => {
    var data: any = {}
    if (addrCounty.countyName !== customerStore.county) {
      data.county = addrCounty.countyName
      data.countyFips = addrCounty.countyFips
      submitResponse(data)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    GetCountiesCityAndStateFromZip(customerStore.postalCode)
  }, [])

  if (isPageLoading) {
    return (
      <ThreeDotsWrapper>
        <ThreeDots color='#222C69' height={80} width={80} />
      </ThreeDotsWrapper>
    )
  }
  const validateAddrCounty = (addrCounty: string) => {
    const addrCountyError = isEmpty(addrCounty)
    setState({
      ...state,
      addrCountyError: addrCountyError,
    })
  }

  const handleAddrCountyChange = (e: SelectChangeEvent<string>) => {
    validateAddrCounty(e.target.value)
    const county = optCounties.find(
      (county) => county.countyName === e.target.value
    )
    if (!isUndefined(county)) setAddrCounty(county)
  }

  return (
    <Container>
      {customerStore.isMAQuotesProcessing}
      {medicareAdvantageFilterQuoteStore.filtersFetched}
      <HeadingContent>
        {`Please update your county to view ${
          tabNumber == 0
            ? 'Medicare Advantage (Part C)'
            : 'Medicare Prescription Drugs (Part D)'
        } Plans`}
        <div></div>
      </HeadingContent>

      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          margin: '10px 0px 4px 0px',
        }}
      >
        <Select
          // error={state.addrCountyError}
          formLabel='Your County'
          options={optCounties.map((county) => county.countyName)}
          width='200px'
          height='39px'
          margin='10px 0px'
          labelMargin='0px 0px 0px 0px'
          value={addrCounty.countyName}
          onChange={handleAddrCountyChange}
        />
      </div>

      <ButtonWrapper>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleSubmit}
          loading={isLoading}
          disabled={
            customerStore.isMAQuotesProcessing ||
            customerStore.isMSQuotesProcessing ||
            customerStore.isMPartDQuotesProcessing
          }
        >
          Save
        </Button>
        {(customerStore.isMAQuotesProcessing ||
          customerStore.isMSQuotesProcessing ||
          customerStore.isMPartDQuotesProcessing) && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )}
      </ButtonWrapper>
    </Container>
  )
}

export default AddMNSPlanDetails
