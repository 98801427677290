import React, { useCallback } from 'react'
import {
  addPrescription,
  getDosageForDrugName,
  getDrugNamesForPrefix,
  getPrescriptions,
  updatePrescription,
} from '../../service/prescriptions'

import Alert from '@mui/material/Alert'
import ApiManager from '../../api/ApiManager'
import BouncingDotsLoader from '../common/Loading/BouncingDotsLoader'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import ModalComponent from '../../components/common/Modal/Modal.component'
import OverlayLoader from '../common/OverlayLoader/OverlayLoader'
import PrescriptionDrugCardInfoSteps from './PrescriptionDrugCardInfoSteps.component'
import { PrescriptionDrugFrequencyEnum } from '../../enums/PrescriptionDrugFrequencyEnum'
import { QuotesRefreshContext } from '../../context/QuotesRefreshContext'
import SearchBar from '../common/Search/Search.component'
import SectionRow from '../common/SectionRow'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { getPlanYear } from '../../utils/CommonUtils'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { pollQuotesPullStatus } from '../../utils/quotesPullStatus'
import prescriptionStore from '../../datastore/PrescriptionStore'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface DrugCard {
  title: string
  dosage: string[]
  frequencyOptions: string[]
  quantity: number
  id: string
  dosageNamesWithUsage?: { name: string; commonDosage: boolean }[]
}

interface DrugDataProps {
  id: string
  name: string
  currentDosage: {
    id: number
    name: string
    qty: number
    strength: string
    strengthUOM: string
    form: string
    alt: boolean
    packages: []
    proxyNdc: string
    commonDosage: boolean
    frequency: number
  }
  isSpouse: boolean
}

interface SearchPromptInfoStepsProps {
  addPrescriptionState: {
    AddedDrugs: any[]
    PrescriptionDrug: any[]
    addingDrugs: boolean
  }
  setAddPrescriptionState: React.Dispatch<
    React.SetStateAction<{
      AddedDrugs: any[]
      PrescriptionDrug: any[]
      addingDrugs: boolean
    }>
  >
  loading?: boolean
  setLoading?: React.Dispatch<boolean>
  handleNext?: () => void
  forSpouse: boolean
  redirect?: boolean
  addDrugLoading?: boolean
  setAddDrugLoading?: (flag: boolean) => void
  isEditDrug?: boolean
  setIsEditDrug?: (flag: boolean) => void
}

const Container = styled.div`
  width: 100%;
  h3 {
    color: ${theme.colors.primary};
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const SearchPromptInfoSteps: React.FC<SearchPromptInfoStepsProps> = ({
  addPrescriptionState,
  setAddPrescriptionState,
  forSpouse,
  loading = false,
  setLoading,
  addDrugLoading,
  setAddDrugLoading = () => {},
  setIsEditDrug = () => {},
  isEditDrug = false,
}) => {
  const [searchDrug, setSearchDrug] = React.useState(false)
  const [isSearching, setIsSearching] = React.useState(false)
  const [overlayLoading, setOverlayLoading] = React.useState(false)
  const [state, setState] = React.useState({
    loading: true,
    searchClickLoading: false,
    showAlert: false,
    alertMessage: '',
  })
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [popUpModal, setpopUpModal] = React.useState<boolean>(false)
  const [genericDrug, setGenericDrug] = React.useState<any>()
  const [loadingFetch, setLoadingFetch] = React.useState<boolean>(false)
  const { isQuotesRefreshing, setIsQuotesRefreshing } =
    React.useContext(QuotesRefreshContext)

  const fetchRxDrugNames = (drugPrefix: string) => {
    setLoadingFetch(true)
    setState({
      ...state,
      loading: true,
    })
    getDrugNamesForPrefix({ $drugPrefix: drugPrefix })
      .then((response: any) => {
        const data = response.data.data.map(
          (x: { id: string; name: string }) => {
            return { title: x.name, id: x.id }
          }
        )
        prescriptionStore.setDrugNameList(data)
        setState({
          ...state,
          loading: false,
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch RX drug name'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setLoadingFetch(false)
      })
  }

  const fetchPrescriptionDrugs = useCallback(() => {
    setState({
      ...state,
      loading: true,
    })
    setOverlayLoading(() => true)
    getPrescriptions({ 'for-spouse': forSpouse })
      .then((response: any) => {
        if (response.status === 200) {
          const data: DrugDataProps[] = response.data.data
          setAddPrescriptionState({
            ...addPrescriptionState,
            AddedDrugs: data,
          })
          setState({
            ...state,
            loading: false,
          })
          if (customerStore.get().forSpouse === false) {
            ApiManager.makeApiCallWithAuthentication(
              UrlConstants.PATCH_INFO_STEPS.USECASE,
              HttpMethods.PATCH_METHOD,
              {
                $infoStep: 'prescription',
              }
            )
              .then((res) => {
                if (res.status === 200) {
                } else throw res
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          }
        } else throw response
      })
      .catch((err) => {
        let message = err.data
          ? err.data.message
            ? err.data.message
            : err.data
          : getApiErrorMessage('fetch prescription drug')

        if (/No prescription drugs are available/.test(message)) return
        else {
          snackbarStore.set({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        }
      })
      .finally(() => {
        setLoading && setLoading(false)
        setOverlayLoading(() => false)
      })
  }, [forSpouse])

  const fetchSearchResults = (searchWord: any) => {
    setGenericDrug({})
    fetchRxDrugNames(searchWord)
  }

  React.useEffect(() => {
    if (!popUpModal) {
      setAddPrescriptionState({
        ...addPrescriptionState,
        addingDrugs: false,
      })
    }
  }, [popUpModal])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    fetchPrescriptionDrugs()
  }, [fetchPrescriptionDrugs])

  const fetchDrugInfo = (
    drugName: string
  ): Promise<{ name: string; commonDosage: boolean }[]> => {
    let data: { name: string; commonDosage: boolean }[] = []
    return new Promise((resolve, reject) => {
      if (!isEmpty(drugName)) {
        setOverlayLoading(() => true)
        getDosageForDrugName({ $drugName: drugName })
          .then((response: any) => {
            if (response.status === 200) {
              const drugDetails = response.data.data.drugs
              const dosageDetails = response.data.data.drugDosage
              data = dosageDetails.dosages.map(
                (x: { id: string; name: string; commonDosage: boolean }) => {
                  return {
                    name: x.name,
                    commonDosage: x.commonDosage,
                  }
                }
              )
              prescriptionStore.setCustomerDosage(dosageDetails.dosages || [])

              if (drugDetails.length > 1) {
                setGenericDrug({
                  genericDrugDosage: dosageDetails.dosages.filter(
                    (item: any) => item.alt === true
                  ),
                  drugName: drugDetails.filter(
                    (item: any) => item.drugType === 'GENERIC'
                  )[0].name,
                })
              }

              // Commented by RB
              // snackbarStore.set({
              //   snackbarMessage:
              //     StringConstants.SNACKBAR_DATA_FETCH_SUCCESS_MSG,
              //   snackbarOpen: true,
              //   snackbarType: SnackbarTypes.SUCCESS,
              // })

              setState({
                ...state,
                showAlert: false,
                alertMessage: '',
              })
              resolve(data)
            } else throw response
          })
          .catch((err) => {
            setState({
              ...state,
              showAlert: true,
              alertMessage: `${drugName} cannot be added. Please try a different drug.`,
            })
            reject(err)
          })
          .finally(() => setOverlayLoading(() => false))
      }
    })
  }

  const handleSearchClick = (item: Object) => {
    if (!isSearching) {
      setOverlayLoading(() => true)
      setIsSearching(true)
      const itemCast: DrugCard = item as DrugCard
      itemCast.frequencyOptions = Object.values(PrescriptionDrugFrequencyEnum)
      itemCast.quantity = prescriptionStore.quantity
      fetchDrugInfo(itemCast.id)
        .then((dosage: { name: string; commonDosage: boolean }[]) => {
          setpopUpModal(true)
          itemCast.dosage = dosage.map((obj) => obj.name)
          itemCast.dosageNamesWithUsage = dosage
          setAddPrescriptionState({
            ...addPrescriptionState,
            addingDrugs: true,
            PrescriptionDrug: [{ ...itemCast, isSpouse: forSpouse }],
          })
          setSearchDrug(false)
        })
        .catch((err) => {
          itemCast.dosage = []
          setSearchDrug(true)
          setpopUpModal(false)
        })
        .finally(() => {
          setIsSearching(false)
          setOverlayLoading(() => false)
        })
    }
  }

  const addPrescriptionDrug = (
    prescriptionDrug: DrugDataProps
  ): Promise<DrugDataProps> => {
    return new Promise((resolve, reject) => {
      setOverlayLoading(() => true)
      addPrescription(prescriptionDrug)
        .then((response: any) => {
          setOverlayLoading(() => false)
          resolve(response.data)
        })
        .catch((err) => {
          setOverlayLoading(() => false)
          reject(err)
        })
    })
  }

  const handleAddDrug = (data: DrugDataProps) => {
    setAddDrugLoading(true)
    setOverlayLoading(() => true)
    setButtonLoading(true)
    addPrescriptionDrug(data)
      .then((response: any) => {
        if (customerStore.get().forSpouse === false) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.PATCH_INFO_STEPS.USECASE,
            HttpMethods.PATCH_METHOD,
            {
              $infoStep: 'prescription',
            }
          )
            .then((res) => {
              if (res.status === 200) {
                setIsQuotesRefreshing(true)
                if (!forSpouse) {
                  medicareAdvantageFilterQuoteStore.clearStore()
                  mPartDPlansFilterStore.clearStore()
                  pollQuotesPullStatus(getPlanYear())
                }
                // TODO: add drugs to store
                customerStore.setInfoSteps([
                  ...customerStore.infoSteps,
                  'prescription',
                ])
              } else throw res
            })
            .catch((err) => {
              snackbarStore.set({
                snackbarMessage: 'Something went wrong',
                snackbarOpen: true,
                snackbarType: 'error',
              })
            })
        }
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        setSearchDrug(false)
        setAddPrescriptionState({
          addingDrugs: false,
          PrescriptionDrug: [],
          AddedDrugs: [response, ...addPrescriptionState.AddedDrugs],
        })
        //TODO: Validate this block before Prod
        try {
          if (!data.isSpouse) {
            medicareAdvantageFilterQuoteStore.setDrugNameOnAdditionOfDrug(
              data.name
            )
            mPartDPlansFilterStore.setDrugNameOnAdditionOfDrug(data.name)
          }
        } catch (error) {
          console.log('Add error: ', error)
        }
      })
      .catch((err) => {
        if (err.data?.message === 'Prescription drug is already been added') {
          snackbarStore.set({
            snackbarMessage: 'Drug has already been added',
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('add prescription drug'),
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        }
      })
      .finally(() => {
        setButtonLoading(false)
        setOverlayLoading(() => false)
        setAddDrugLoading(false)
      })
  }

  const updatePrescriptionDrug = (
    prescriptionDrug: Partial<DrugDataProps>
  ): Promise<DrugDataProps> => {
    return new Promise((resolve, reject) => {
      updatePrescription(prescriptionDrug)
        .then((response: any) => {
          if (response.status === 200) {
            if (!forSpouse) {
              medicareAdvantageFilterQuoteStore.clearStore()
              mPartDPlansFilterStore.clearStore()
              setIsQuotesRefreshing(true)
              pollQuotesPullStatus(getPlanYear())
            }
            resolve(response.data.data)
          } else throw response
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const handleEditDrug = (data: Partial<DrugDataProps>) => {
    setAddDrugLoading(true)
    setState({
      ...state,
    })
    setButtonLoading(true)
    setOverlayLoading(() => true)
    updatePrescriptionDrug(data)
      .then((response: any) => {
        // TODO: add drugs to store
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        let index = addPrescriptionState.AddedDrugs.findIndex(
          (x: DrugDataProps) => x.id === response.id
        )
        let addedDrugsAfterUpdate = addPrescriptionState.AddedDrugs
        addedDrugsAfterUpdate[index] = response
        setSearchDrug(false)
        setAddPrescriptionState({
          addingDrugs: false,
          PrescriptionDrug: [],
          AddedDrugs: addedDrugsAfterUpdate,
        })
        setIsEditDrug(false)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update prescription drug'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        setIsEditDrug(true)
      })
      .finally(() => {
        setState({
          ...state,
        })
        setButtonLoading(false)
        setOverlayLoading(() => false)
        setAddDrugLoading(false)
      })
  }

  const handleCancel = () => {
    setGenericDrug({})
    setpopUpModal(false)
    setIsEditDrug(false)
    if (addPrescriptionState.addingDrugs) {
      setSearchDrug(false)
    } else setSearchDrug(true)
    setAddPrescriptionState({
      ...addPrescriptionState,
      PrescriptionDrug: [],
      addingDrugs: false,
    })
  }

  const getDrugName = () => {
    return state.alertMessage.split(
      ' cannot be added. Please try a different drug.'
    )[0]
  }

  return loading ? (
    <BouncingDotsLoader />
  ) : (
    <Container>
      <h3>Add your {forSpouse ? "Spouse/Partner's" : ''} prescription drugs</h3>
      <p style={{ fontWeight: '400' }}>
        By knowing your prescriptions, we can calculate plan coverage pricing
        and your out-of-pocket costs.
      </p>{' '}
      <br />
      {state.showAlert && state.alertMessage && (
        <Alert
          severity='error'
          onClose={() => {
            setState({
              ...state,
              showAlert: false,
              alertMessage: '',
            })
          }}
        >
          <i>{getDrugName()}</i> cannot be added. Please try a different drug.
        </Alert>
      )}
      {!popUpModal && (
        <SectionRow row>
          <SearchBar
            label='Add your drugs'
            placeholder='Type your drugs name'
            handleSearchClick={handleSearchClick}
            isSearching={isSearching}
            data={prescriptionStore.get().drugNames}
            minInputLengthBeforeFiltering={3}
            disabled={isQuotesRefreshing}
            fetchSearchResults={fetchSearchResults}
            loading={loadingFetch}
          />
          {isQuotesRefreshing && (
            <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
          )}
        </SectionRow>
      )}
      {((addPrescriptionState.addingDrugs && !searchDrug && popUpModal) ||
        isEditDrug) && (
        <ModalComponent
          setOpen={() => setpopUpModal(false)}
          description='Popup - User Information Prompt'
          hideCloseButton={true}
        >
          <PrescriptionDrugCardInfoSteps
            handleAddDrugs={handleAddDrug}
            handleEditDrugs={handleEditDrug}
            handleCancel={handleCancel}
            buttonLoading={buttonLoading}
            data={addPrescriptionState.PrescriptionDrug[0]}
            addDrugLoading={addDrugLoading}
            setpopUpModal={setpopUpModal}
            genericDrug={genericDrug}
          />
        </ModalComponent>
      )}
      {overlayLoading && <OverlayLoader />}
    </Container>
  )
}

export default SearchPromptInfoSteps
