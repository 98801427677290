import React, { useState } from 'react'
import { addSpouseDetails, updateInfoStep } from '../../../../service/customer'
import { isEmpty, toNumber } from 'lodash'

import BottomStepNavigator from '../../BottomStepNavigator.component'
import { InfoStepEnum } from '../../../../enums/InfoStepEnum'
import InputField from '../../../common/InputField/InputField.component'
import Label from '../../../common/Label/Label'
import RadioQuestion from '../../../ProfilePage/common/RadioQuestion'
import RouteConstants from '../../../../constants/RouteConstants'
import SectionRow from '../../../common/SectionRow'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import ValidationUtils from '../../../../utils/validation/ValidationUtils'
import YearMonthDaySelector from '../../../common/Select/YearMonthDaySelector'
import { capitalizeFirstCharacter } from '../../../../utils/StringUtils'
import customerStore from '../../../../datastore/CustomerStore'
import { getPlanYear } from '../../../../utils/CommonUtils'
import { observer } from 'mobx-react'
import { pollQuotesPullStatus } from '../../../../utils/quotesPullStatus'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'

interface SpouseInfoQuestionProps {
  handleBack: () => void
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  resetSteps: () => void
}

const QuestionWrapper = styled.div`
  text-align: start;
  padding: 10px 0px 20px 0px;
  font-weight: 700;
  font-size: 18px;
  color: ${theme.colors.primary};
`

const SpouseInfoQuestion: React.FC<SpouseInfoQuestionProps> = (props) => {
  const navigate = useNavigate()

  const [year, setYear] = useState<number | ''>(
    !isEmpty(customerStore.spouseDateOfBirth.split('-')[0])
      ? toNumber(customerStore.spouseDateOfBirth.split('-')[0])
      : ''
  )
  const [month, setMonth] = useState<number | ''>(
    !isEmpty(customerStore.spouseDateOfBirth.split('-')[1])
      ? toNumber(customerStore.spouseDateOfBirth.split('-')[1])
      : ''
  )
  const [day, setDay] = useState<number | ''>(
    !isEmpty(customerStore.spouseDateOfBirth.split('-')[2])
      ? toNumber(customerStore.spouseDateOfBirth.split('-')[2])
      : ''
  )
  const [errors, setErrors] = useState({ year: '', month: '', day: '' })
  const [firstName, setFirstName] = React.useState(
    customerStore.spouseFirstName
  )
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [firstNameHelperText, setFirstNameHelperText] = React.useState('')
  const [lastName, setLastName] = React.useState(customerStore.spouseLastName)
  const [lastNameError, setLastNameError] = React.useState(false)
  const [lastNameHelperText, setLastNameHelperText] = React.useState('')
  const [gender, setGender] = useState<'male' | 'female' | ''>(
    customerStore.spouseGender as 'male' | 'female' | ''
  )
  const [genderError, setGenderError] = React.useState<string>('')

  const validateDate = () => {
    const newErrors = { year: '', month: '', day: '' }

    if (year === '') newErrors.year = 'Required'
    if (month === '') newErrors.month = 'Required'
    if (day === '') newErrors.day = 'Required'

    if (year && month && day) {
      const date = new Date(year, month - 1, day)
      if (
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      ) {
        newErrors.day = 'Invalid date'
      }
    }

    setErrors(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setFirstNameError(firstNameError)
    setFirstNameHelperText(
      isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
        : ''
    )
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFirstName(e.target.value)
    setFirstName(e.target.value)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setLastNameError(lastNameError)
    setLastNameHelperText(
      isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
        : ''
    )
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateLastName(e.target.value)
    setLastName(e.target.value)
  }

  const handleResponseSelection = (id: any, responseArray: any) => {
    setGender(responseArray[0])
  }

  const validateGender = () => {
    if (isEmpty(gender)) {
      setGenderError('Required')
    }
  }

  const handleAddSpouseDetails = (
    dob: string,
    gender: string,
    fName: string,
    lName: string
  ) => {
    props.setLoading(true)
    addSpouseDetails(fName, lName, dob, gender)
      .then(() => {
        customerStore.isMAQuotesProcessing = true
        customerStore.isMSQuotesProcessing = true
        customerStore.isMPartDQuotesProcessing = true
        pollQuotesPullStatus(getPlanYear())
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        customerStore.setSpouseBasicDetails({
          spouseDateOfBirth: dob,
          spouseFirstName: fName,
          spouseGender: gender,
          spouseLastName: lName,
          spouseOptIn: true,
        })
        customerStore.setForSpouse(true)
        customerStore.setSpouseOptIn(true)
        updateInfoStep(InfoStepEnum.QUESTIONNAIRE)
          .then(() => {
            snackbarStore.set({
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarOpen: true,
              snackbarType: 'success',
            })
            props.resetSteps()
            navigate(`${RouteConstants.INFO_STEPS_HEALTH}`)
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: 'Unable to add spouse details, try again later',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        props.setLoading(false)
      })
  }

  const handleSubmit = () => {
    validateFirstName(firstName)
    validateLastName(lastName)
    validateGender()
    if (
      validateDate() &&
      !isEmpty(firstName) &&
      !isEmpty(lastName) &&
      !isEmpty(gender)
    ) {
      handleAddSpouseDetails(
        `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`,
        gender.toLowerCase(),
        firstName,
        lastName
      )
    }
  }

  return (
    <>
      <QuestionWrapper>Enter spouse/household details below.</QuestionWrapper>
      <SectionRow row>
        <InputField
          isRequired
          label='Your Spouse/Partner’s first name'
          error={firstNameError}
          helperText={firstNameHelperText}
          value={firstName}
          placeholder='First name'
          width='100%'
          color='primary'
          onChange={handleFirstNameChange}
        />

        <InputField
          isRequired
          label="Your Spouse/Partner's last name"
          error={lastNameError}
          helperText={lastNameHelperText}
          value={lastName}
          placeholder='Last name'
          width='100%'
          color='primary'
          onChange={handleLastNameChange}
        />
      </SectionRow>
      <SectionRow>
        <YearMonthDaySelector
          isRequired
          label='Spouse/Partner Date of Birth'
          onYearChange={setYear}
          onMonthChange={setMonth}
          onDayChange={setDay}
          errors={errors}
          setErrors={setErrors}
          loading={props.loading}
          year={year}
          month={month}
          day={day}
        ></YearMonthDaySelector>
      </SectionRow>
      <SectionRow>
        <RadioQuestion
          row
          squared={false}
          handleResponseSelection={handleResponseSelection}
          id={Math.random()}
          isRequired={true}
          options={[{ option: 'Male' }, { option: 'Female' }]}
          question={'Spouse/Partner Gender'}
          value={capitalizeFirstCharacter(gender)}
          loading={props.loading}
          error={isEmpty(genderError) ? false : true}
        />
      </SectionRow>
      <BottomStepNavigator
        handleNext={handleSubmit}
        handleBack={props.handleBack}
        handleViewPlans={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          navigate(RouteConstants.DASHBOARD)
        }}
        isLoading={props.loading}
        currentInfoStep='Questionnaires'
        currentSectionCount={1}
        currentQuestionCount={8}
        totalQuestionCount={9}
      />
    </>
  )
}

export default observer(SpouseInfoQuestion)
