import { Divider, Table } from '@mui/material'
import { cloneDeep, isEmpty, isUndefined } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

import ApiManager from '../../../../api/ApiManager'
import Button from '../../../common/Button/Button.component'
import CarrierLogo from '../../../common/CarrierLogo'
import Coverage from '../../Coverage.component'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MSDiscountType } from '../../../../interface/quotes/mSupplementPlans/MSDiscountType'
import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlansRiderType } from '../../../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import NonStdToStdPlans from '../../../../constants/NonStdToStdPlans'
import PlanCardFooter from '../../Common/PlanCardFooter'
import React from 'react'
import RemoveSpouseConfirmationPopUp from '../../Modals/RemoveSpouseConfirmation'
import RidersPlans from '../../../../constants/RidersPlans'
import RidersSelectionModal from '../../Modals/RidersSelectionModal'
import RouteConstants from '../../../../constants/RouteConstants'
import { StateAndMSPlanTypesConstants } from '../../../../constants/StateAndMSPlanTypes'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import { constructCompletePlanDetail } from '../../../../utils/MedicareQuotesUtils'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateEnrollmentRequest } from '../../../../service/quotes'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div<{
  isRecommended?: boolean
  isGoodChoice?: boolean
}>`
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
  border: ${(props) =>
    props.isRecommended
      ? `2px solid ${theme.colors.success}`
      : props.isGoodChoice
      ? `2px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.borderColor}`};
  border-radius: 16px;
  margin: 20px 0px;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.4s;
  }

  .recommended-banner {
    background-color: ${theme.colors.contrastGreen};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.green};
      font-size: 18px;
    }
  }

  .good-choice-banner {
    background-color: ${theme.colors.contrastPrimary};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.primary};
      font-size: 18px;
    }
  }
`

const Header = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  color: ${theme.colors.textDark};
  div {
    display: flex;
    align-items: center;
  }
  .title {
    gap: 30px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
      max-width: 100px;
      max-height: 40px;
    }
    h2 {
      font-size: 18px;
    }
    .carrier_name {
      width: 70%;
    }
    .plan_type {
      width: 20%;
      justify-content: flex-end;
    }
  }
  .sub_title {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    display: flex;
    align-items: center;
    .star {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .plan_type_mobile {
    display: none;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
    .title {
      gap: 12px;
      width: 100%;
      img {
        max-width: 100px;
        max-height: 50px;
      }
      .carrier_name {
        width: 100%;
      }
      .plan_type {
        display: none;
      }
    }
    .sub_title {
      font-size: 16px;
      margin-top: 12px;
      flex-direction: column;
      justify-content: left;
    }
    .plan_type_mobile {
      h2 {
        font-size: 24px;
      }
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
  }
`
const MonthlyPremiumWrapper = styled.div<{ isInGrid: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};

  .premium_details {
    display: flex;
    flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
    .premium_info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.textGrey};
      .premium_value {
        font-size: 24px;
        font-weight: 700;
        color: ${theme.colors.primary};
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 10px 20px;
    .premium_details {
      display: flex;
      flex-direction: column;
    }
  }
`

// const ContentWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   border-bottom: 1px solid ${theme.colors.borderColor};
//   padding: 16px 0px;

//   @media screen and (max-width: 530px) {
//     padding: 20px 0px;
//   }

//   @media screen and (max-width: 500px) {
//     flex-direction: column;
//   }
// `

// const BenefitsWrapper = styled.div`
//   flex: 1.5;
//   border-right: 1px solid ${theme.colors.borderColor};
//   padding: 0px 30px;
//   @media screen and (max-width: 530px) {
//     padding: 0px 10px;
//   }

//   @media screen and (max-width: 500px) {
//     border-right: none;
//     border-bottom: 1px solid ${theme.colors.borderColor};
//     margin-bottom: 20px;
//   }
// `

// const PricingWrapper = styled.div`
//   flex: 1;
//   padding: 6px 20px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   width: 100%;
//   h2 {
//     font-size: 14px;
//     color: ${theme.colors.textGrey};
//     // padding-bottom: px;
//   }
//   p {
//     font-size: 24px;
//     font-weight: bold;
//     color: ${theme.colors.primary};
//     padding-bottom: 10px;
//     :nth-of-type(1) {
//       padding: 4px 0px;
//     }
//   }

//   .info {
//     font-size: 12px;
//     font-weight: 600;
//     color: ${theme.colors.textDark};
//   }

//   div {
//     padding: 4px 0px 0px 0px;
//     p {
//       margin-top: 4px;
//       font-size: 14px;
//     }
//     span {
//       font-size: 12px;
//       color: ${theme.colors.textGrey};
//       font-weight: 600;
//     }
//   }
//   .discounts {
//     span {
//       margin-right: 6px;
//     }
//   }

//   @media screen and (max-width: 1250px) {
//     .discounts {
//       :nth-of-type(1) {
//         max-width: 100px;
//       }
//       :nth-of-type(2) {
//         max-width: 60px;
//         padding-right: 4px;
//         padding-top: 16px;
//       }
//       :nth-of-type(3) {
//         padding-top: 16px;
//       }
//     }
//   }
//   @media screen and (max-width: 623px) {
//     .policyFee {
//       :nth-of-type(1) {
//         max-width: 60px;
//       }
//       :nth-of-type(2) {
//         max-width: 60px;
//         padding-right: 4px;
//         padding-top: 16px;
//       }
//       :nth-of-type(3) {
//         padding-top: 16px;
//       }
//     }
//   }
//   @media screen and (max-width: 565px) {
//     padding: 6px 10px;
//   }
//   @media screen and (max-width: 545px) {
//     padding: 0px 10px;
//   }
//   @media screen and (max-width: 533px) {
//     .discounts {
//       :nth-of-type(2),
//       :nth-of-type(3) {
//         padding-top: 30px;
//       }
//     }
//   }
//   @media screen and (max-width: 530px) {
//     padding: 6px 10px;
//     .discounts {
//       :nth-of-type(2),
//       :nth-of-type(3) {
//         padding-top: 16px;
//       }
//     }
//   }
//   @media screen and (max-width: 500px) {
//     padding: 0px 10px;

//     p {
//       padding-bottom: 10px;
//     }
//     .discounts,
//     .policyFee {
//       :nth-of-type(1) {
//         max-width: initial;
//       }
//       :nth-of-type(2) {
//         max-width: initial;
//         padding-right: initial;
//         padding-top: initial;
//       }
//       :nth-of-type(3) {
//         padding-top: initial;
//       }
//     }
//   }
// `

// const BenefitContent = styled.div`
//   padding-bottom: 8px;
//   h2 {
//     font-size: 14px;
//     color: ${theme.colors.textGrey};
//     padding-bottom: 10px;
//   }
//   p {
//     font-size: 12px;
//     color: ${theme.colors.textGrey};
//     padding-bottom: 10px;
//     display: flex;
//   }
//   .deductible {
//     font-size: 36px;
//     font-weight: bold;
//     color: ${theme.colors.primary};
//   }
//   div {
//     display: flex;
//     flex-wrap: wrap;
//     p {
//       padding-right: 15px;
//       padding-bottom: 3px;
//       font-size: 14px;
//       color: ${theme.colors.textGrey};
//       display: flex;
//       align-items: center;
//       span {
//         margin-right: 2px;
//       }
//     }
//   }
// `

// const BenefitsRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
// `

// const FeaturesRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-items: flex-start;
//   width: 100%;
//   margin-top: 1.5rem;
//   font-size: 14px;
// `

// const FeaturesList = styled.ul`
//   display: flex;
//   flex-direction: column;
//   font-size: 14px;
// `

// const FeaturesListItem = styled.li`
//   display: flex;
//   align-items: center;
//   padding: 2px 0;
//   margin-left: 48px;
// `

// const FeatureName = styled.span`
//   margin-left: 8px;
//   font-size: 12px;
// `

// const FooterWrapper = styled.div`
//   padding: 2px 20px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   div {
//     display: flex;
//     align-items: center;
//     span {
//       font-size: 14px;
//     }
//     p {
//       color: ${theme.colors.primary};
//       font-size: 14px;
//     }
//   }

//   @media screen and (max-width: 530px) {
//     padding: 0px;
//     padding-bottom: 10px;
//     flex-wrap: wrap;
//     div {
//       flex-wrap: wrap;
//       span {
//         font-size: 12px;
//       }
//       p {
//         font-size: 12px;
//       }
//     }
//   }
// `

const MoreDetailsWrapper = styled.div`
  padding: 10px 40px;
  p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      text-decoration: underline;
      font-weight: 400;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
`

const NavigationWrapper = styled.div`
  text-decoration: underline;
  font-weight: 600;
  color: ${theme.colors.primary};
  font-size: 14px;
  margin-left: 20px;
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)`
  font-weight: 520;
  color: ${theme.colors.textGrey};
  font-size: 14px;
  line-height: 20px;
  &.MuiTableCell-root {
    width: 50%;
  }
`

// interface MSPlanWithGenericDetails extends MSPlan {
//   genericPlanDetails: MSPlanGenericDetails[]
// }

const MedicareSupplementCard: React.FC<{
  msPlan: MSPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  onReload?: () => void
  medigapChartDetails?: any
  userOption: boolean
  handleSpouseRemove: () => void
  setShowAddSpouseConfirmation: (flag: boolean) => void
  setCompletePlanDetailModalActive: (flag: boolean) => void
  setCompleteDetailPlanId: (planId: string) => void
  isRecommended?: boolean
  isGoodChoice?: boolean
}> = ({
  msPlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
  onReload,
  medigapChartDetails,
  userOption,
  handleSpouseRemove,
  setShowAddSpouseConfirmation,
  setCompletePlanDetailModalActive,
  setCompleteDetailPlanId,
  isRecommended = false,
  isGoodChoice = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [primaryEnrollmentStatus, setPrimaryEnrollmentStatus] = React.useState(
    msPlan.enrollRequested
  )

  const [spouseEnrollmentStatus, setSpouseEnrollmentStatus] = React.useState(
    msPlan.spouseEnrollRequested
  )

  const [spouseRequestedEnrollment, setSpouseRequestedEnrollment] =
    React.useState<boolean>(false)

  const [showRemoveSpouseConfirmation, setShowRemoveSpouseConfirmation] =
    React.useState(false)
  // const [modifiedPlanDetail, setModifiedPlanDetail] =
  //   React.useState<MSPlanGenericDisplayDetail>()
  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)
  // const enrollBtnText = enrollRequested ? 'Application Requested' : 'APPLY'
  const [loading, setLoading] = React.useState(false)
  // const [msPlan, setMSPlan] = React.useState<MSPlan>()
  const [riders, setRiders] = React.useState<MSPlansRiderType[]>([])

  const [isPlanBenefitExpanded, setIsPlanBenefitExpanded] = React.useState(true)

  const handleIsPlanBenefitExpansion = (flag: boolean) => {
    setIsPlanBenefitExpanded(flag)
  }

  const handleExpandCard = (msPlan: MSPlan) => {
    customerStore.setMSExpandedCardDetails(
      cloneDeep(constructCompletePlanDetail(msPlan))
    )
    if (isExpanded === true) {
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
      setIsExpanded(false)
    }
    if (isExpanded === false) {
      setIsExpanded(true)
    }
    if (
      `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
      customerStore.searchPlan
    ) {
      setIsExpanded(false)
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
    }
  }

  const navigate = useNavigate()
  const [isFavorite, setIsFavorite] = React.useState(msPlan.planSaved || false)
  const [openRiders, setOpenRiders] = React.useState(false)
  const [monthlyPremium, setMonthlyPremium] = React.useState(
    msPlan.mSupplementRate.month / 100
  )
  const [annualCost, setAnnualCost] = React.useState(msPlan.estimatedAnnualCost)
  const [showRiders, setShowRiders] = React.useState(false)
  const [stdPlans, setStdPlans] = React.useState<string[]>([])
  const [applyRidersLoading, setApplyRidersLoading] = React.useState(false)
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )
  const location = useLocation()

  React.useEffect(() => {
    const tempRiders: MSPlansRiderType[] = []
    let tempStdPlans: string[] = []
    msPlan.riders.forEach((rider: any) => {
      let x: MSPlansRiderType = JSON.parse(rider)
      tempRiders.push(x)
    })
    setRiders(tempRiders)
    let tempShowRiders: boolean | undefined
    if (!isEmpty(tempRiders)) {
      if (Object.keys(RidersPlans).includes(msPlan.msCompanyBase.name)) {
        if (!isEmpty(RidersPlans[msPlan.msCompanyBase.name])) {
          if (
            Object.keys(RidersPlans[msPlan.msCompanyBase.name]).includes(
              msPlan.planName
            )
          ) {
            if (
              !isEmpty(RidersPlans[msPlan.msCompanyBase.name][msPlan.planName])
            ) {
              tempStdPlans =
                NonStdToStdPlans[msPlan.msCompanyBase.name][msPlan.planName]
              tempShowRiders = true
            } else tempShowRiders = false
          }
        }
      }
      if (isUndefined(tempShowRiders)) {
        //carrierKey === 'Others'
        if (Object.keys(RidersPlans['Others']).includes(msPlan.planName)) {
          if (isEmpty(RidersPlans['Others'][msPlan.planName])) {
            tempShowRiders = false
          } else {
            tempStdPlans = NonStdToStdPlans['Others'][msPlan.planName]
            tempShowRiders = true
          }
        } else tempShowRiders = true
      }
      tempShowRiders = tempShowRiders || false
      setStdPlans(tempStdPlans)
      setShowRiders(tempShowRiders)
    }
    let ridersApplied = msPlan.ridersApplied
    if (!isUndefined(ridersApplied)) {
      if (customerStore.get().isRidersApplied) {
        setMonthlyPremium(msPlan.mSupplementRate.monthWithRidersApplied / 100)
        setAnnualCost(
          msPlan.estimatedAnnualCost +
            ((msPlan.mSupplementRate.monthWithRidersApplied -
              msPlan.mSupplementRate.month) *
              12) /
              100
        )
      }
    }
  }, [])

  const handleOpenRiders = () => {
    setOpenRiders(!openRiders)
  }

  const handleEnrollRequest = () => {
    if (
      isEmpty(userActionStore.getRidersApplied(msPlan.id)) &&
      !isEmpty(msPlan.ridersApplied)
    ) {
      userActionStore.setRidersApplied(msPlan.id, msPlan.ridersApplied!)
    }
    setLoading(true)
    const riders = msPlan.id
      ? userActionStore.getRidersAppliedWithFullNames(msPlan.id).length > 0
        ? userActionStore.getRidersAppliedWithFullNames(msPlan.id)
        : undefined
      : undefined
    updateEnrollmentRequest(
      spouseRequestedEnrollment ? msPlan.spousePlanId : msPlan.id,
      MedicareQuotesType.MEDICARE_SUPPLEMENT,
      spouseRequestedEnrollment,
      `+1${preferredPhone.toString()}`,
      riders?.toString()
    )
      .then(() => {
        setLoading(false)
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Apply request saved successfully',
          snackbarType: 'success',
        })
        if (spouseRequestedEnrollment) setSpouseEnrollmentStatus(true)
        else setPrimaryEnrollmentStatus(true)
        setEnrollmentRequestPromptActive(false)
        onEnroll()
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save apply request. Maximum limit reached'
            ),
            snackbarType: 'error',
          })
          setPrimaryEnrollmentStatus(false)
          setEnrollmentRequestPromptActive(false)
        } else {
          console.log('err', err)
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save apply request'),
            snackbarType: 'error',
          })
          setPrimaryEnrollmentStatus(false)
          setEnrollmentRequestPromptActive(false)
        }
      })
  }

  const updateRidersApplied = (planId: string) => {
    setApplyRidersLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_RIDERS_APPLIED_FOR_MS_PLAN.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $planId: planId,
        ridersApplied: userActionStore.getRidersApplied(planId),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          customerStore.setIsRidersApplied(true)
          msPlanFiltersStore.setFilterApplied(true)
          if (location.pathname === RouteConstants.SAVED_PLANS)
            onReload && onReload()
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update riders for the plan'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setOpenRiders(false)
        setApplyRidersLoading(false)
      })
  }

  const handleRidersApplied = (plan: MSPlan) => {
    updateRidersApplied(plan.id)
  }

  // const [currentPlanAnnualCost, setCurrentPlanAnnualCost] =
  //   React.useState<number>(0)
  // const [combinedCurrentPlanAnnualCost, setCombinedCurrentPlanAnnualCost] =
  //   React.useState<number>(0)
  // const [currentPlanExist, setCurrentPlanExistStatus] =
  //   React.useState<boolean>(false)

  // React.useEffect(() => {
  //   setMSPlan(msPlan)
  // }, [msPlan])

  // if (msPlan && msPlan.currentPlanStatus) {
  //   setCurrentPlanAnnualCost(msPlan.estimatedAnnualCost)
  //   setCombinedCurrentPlanAnnualCost(msPlan.combinedEstimatedAnnualCost)
  //   setCurrentPlanExistStatus(true)
  // }

  const onAddFavPlan = (planId: string) => {
    setIsFavorite(true)
  }

  const onRemoveFavPlan = (planId: string) => {
    setIsFavorite(false)
    handleRemoveFavorite && handleRemoveFavorite()
  }

  const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      if (
        userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_SUPPLEMENT
      ) {
        if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_SUPPLEMENT, {
            id: msPlan.id,
            name: `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`,
            premium: msPlan.mSupplementRate.month / 100,
            carrier: msPlan.msCompanyBase.name,
            enrollRequestStatus: msPlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
            medicareSupplementPlanType: 'Plan ' + msPlan.planName,
            ridersApplied: msPlan.ridersApplied,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: 'You can only add up to 3 plans for comparison',
            snackbarOpen: true,
            snackbarType: 'default',
          })
        }
      } else {
        userActionStore.addCard(MedicareQuotesType.MEDICARE_SUPPLEMENT, {
          id: msPlan.id,
          name: `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`,
          premium: msPlan.mSupplementRate.month / 100,
          carrier: msPlan.msCompanyBase.name,
          enrollRequestStatus: msPlan.enrollRequested,
          planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
          medicareSupplementPlanType: 'Plan ' + msPlan.planName,
          ridersApplied: msPlan.ridersApplied,
        })
      }
    }

    if (!e.currentTarget.checked) {
      userActionStore.removeCard(msPlan.id)
    }
  }

  const getDetailedDescription = (planName: string) => {
    let detailedDescription = planName
    if (planName.startsWith('MA')) {
      detailedDescription += ' (Massachussets'
      switch (planName) {
        case 'MA_CORE':
          detailedDescription += ' Core Plan)'
          break
        case 'MA_SUPP1':
          detailedDescription += ' Supplement 1 Plan)'
          break
        case 'MA_SUPP1A':
          detailedDescription += ' Supplement 1A Plan)'
          break
        default:
          detailedDescription = planName
      }
    } else if (planName.startsWith('MN')) {
      detailedDescription += ' (Minnesota'
      switch (planName) {
        case 'MN_PBCO':
          detailedDescription += ' Part B Copay/Coinsurance Plan)'
          break
        case 'MN_BASIC':
          detailedDescription += ' Basic Plan)'
          break
        case 'MN_EXTB':
          detailedDescription += ' Extended Basic Plan)'
          break
        case 'MN_XBAS2':
          detailedDescription += ' Extended Basic Plan - New)'
          break
        case 'MN_HDED':
          detailedDescription += ' High Deductible Plan)'
          break
        case 'MN_HDED2':
          detailedDescription += ' High Deductible Plan - New)'
          break
        case 'MN_50%':
          detailedDescription += ' 50% Cost Sharing Plan)'
          break
        case 'MN_75%':
          detailedDescription += ' 75% Cost Sharing Plan)'
          break
        case 'MN_A50%':
          detailedDescription += ' 50 percent Part A Deductible Coverage Plan)'
          break
        default:
          detailedDescription = planName
      }
    } else if (planName.startsWith('WI')) {
      detailedDescription += ' (Wisconsin'
      switch (planName) {
        case 'WI_BASE':
          detailedDescription += ' Basic Plan)'
          break
        case 'WI_HDED':
          detailedDescription += ' High Deductible Plan)'
          break
        case 'WI_50%':
          detailedDescription += ' 50% Cost Sharing Plan)'
          break
        case 'WI_75%':
          detailedDescription += ' 75% Cost Sharing Plan)'
          break
        default:
          detailedDescription = planName
      }
    } else {
      if (planName === 'HDF') detailedDescription += ' (High Deductible Plan F)'
      else if (planName === 'HDG')
        detailedDescription += ' (High Deductible Plan G)'
    }
    return detailedDescription
  }

  const storeScrollPosition = () => {
    const scrollPosition = window.scrollY
    customerStore.setScrollPosition(scrollPosition.toString())
  }

  const monthlyPremiumDivider = (
    userFName: string,
    monthlyPremium: number,
    buttonVariant: 'text' | 'outlined' | 'contained' | undefined,
    carrierName: string,
    planName: string,
    enrollRequestedFlag: boolean,
    enrollBtnText: string,
    forSpouse: boolean,
    color?:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'contrastPrimary'
      | undefined
  ) => {
    return (
      <>
        <div className='premium_info'>
          <p>{`${userFName || 'Spouse'}'s Monthly Premium :`}</p>
          <p className='premium_value'>{convertToUSCurrency(monthlyPremium)}</p>
          <Button
            variant={buttonVariant}
            color={color}
            onClick={() => {
              setSpouseRequestedEnrollment(forSpouse)
              setEnrollmentRequestPromptActive(true)
            }}
            disabled={enrollRequestedFlag}
            description={`Attempted Enrollment in  ${carrierName} plan ${planName}`}
            id={`enroll  ${carrierName} plan ${planName}`}
            width='250px'
          >
            {enrollBtnText}
          </Button>
        </div>
      </>
    )
  }

  const hhdAndPolicyFees = (
    discounts: MSDiscountType[],
    policyFees: number
  ) => {
    return (
      <>
        {isEmpty(discounts) &&
        (isUndefined(policyFees) || policyFees === -1) ? (
          <></>
        ) : (
          <Divider
            orientation='vertical'
            flexItem
            style={{ margin: '0px 10px' }}
          />
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {discounts && discounts.find((x) => x.name === 'household') && (
            <p
              style={{
                display: 'flex',
                paddingTop: '4px',
                margin: '0px auto',
                alignItems: 'center',
                fontWeight: '600',
                fontSize: '12px',
                color: theme.colors.textDark,
                textAlign: 'center',
              }}
            >
              <span className='discounts'>
                With Household Discount*
                <Tooltip
                  title={StringConstants.TOOLTIP_MSG_MS_HH_DISCOUNT}
                ></Tooltip>
                <> </>
              </span>
              <span className='discounts'>:</span>
              <span
                className='discounts'
                style={{
                  color: theme.colors.primary,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '0.25rem',
                  fontWeight: 'bold',
                }}
              >
                $
                {msPlan.withHHD && msPlan.withHHD !== 0
                  ? msPlan.withHHD && (msPlan.withHHD / 100).toFixed(2)
                  : (
                      monthlyPremium *
                      (1 -
                        (discounts.find((x) => x.name === 'household')?.value ||
                          0))
                    ).toFixed(2)}
              </span>
            </p>
          )}
          {!isUndefined(policyFees) && policyFees !== -1 ? (
            <>
              <p
                style={{
                  display: 'flex',
                  paddingTop: '4px',
                  margin: '0px auto',
                  alignItems: 'center',
                  fontWeight: '600',
                  fontSize: '12px',
                  color: theme.colors.textDark,
                  textAlign: 'center',
                }}
              >
                <span className='policyFee'>One-time policy fee</span>
                <span className='policyFee'>:</span>
                <span
                  className='policyFee'
                  style={{
                    color: theme.colors.primary,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '0.25rem',
                    fontWeight: 'bold',
                  }}
                >
                  ${policyFees.toFixed(2)}
                </span>
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  return (
    <Container isGoodChoice={isGoodChoice} isRecommended={isRecommended}>
      {isRecommended && (
        <div className='recommended-banner'>
          <h2>RECOMMENDATION</h2>
        </div>
      )}
      {isGoodChoice && (
        <div className='good-choice-banner'>
          <h2>GOOD CHOICE</h2>
        </div>
      )}
      {enrollmentRequestPromptActive && (
        <ModalComponent
          setOpen={() => {
            setEnrollmentRequestPromptActive(false)
          }}
          description='Popup - Request for Enrollment Confirmation'
          hideCloseButton={true}
        >
          <EnrollmentRequestConfirmation
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            onEnroll={() => {
              handleEnrollRequest()
            }}
            planName={
              msPlan.msCompanyBase.name + ' (Plan ' + msPlan.planName + ')'
            }
            planId={msPlan.id}
            planType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
            buttonLoading={loading}
            preferredPhoneNumber={preferredPhone}
            setPreferredPhoneNumber={setPreferredPhone}
          />
        </ModalComponent>
      )}

      {showRemoveSpouseConfirmation && (
        <ModalComponent
          setOpen={() => {
            setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
          }}
          description='Popup - Request for Enrollment Confirmation'
          hideCloseButton={true}
        >
          <RemoveSpouseConfirmationPopUp
            onConfirm={() => {
              handleSpouseRemove()
            }}
            onReject={() => {
              setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
            }}
          />
        </ModalComponent>
      )}

      {openRiders && (
        <ModalComponent
          setOpen={() => {
            setOpenRiders(false)
          }}
          description='Popup - Select Riders'
        >
          <RidersSelectionModal
            setOpen={() => {
              setOpenRiders(false)
            }}
            buttonLoading={applyRidersLoading}
            planId={msPlan.id}
            planName={msPlan.planName}
            carrierName={msPlan.msCompanyBase.name}
            planRiders={riders}
            onApply={() => {
              handleRidersApplied(msPlan)
            }}
            stdPlans={stdPlans}
            appliedRiders={
              msPlan.ridersApplied
                ? msPlan.ridersApplied
                : userActionStore.getRidersApplied(msPlan.id)
            }
            isPlanEnrolmentRequested={msPlan.enrollRequested}
          />
        </ModalComponent>
      )}

      {msPlan && (
        <>
          <Header>
            <div className='title'>
              <CarrierLogo
                carrier={msPlan.msCompanyBase.name}
                useAltLogo={true}
              />
              <div className='carrier_name'>
                <h2>
                  {msPlan.msCompanyBase.name} (Plan {msPlan.planName})
                </h2>
              </div>
              <div className='plan_type'>
                <h2>Plan {msPlan.planName}</h2>
              </div>
            </div>
            <div className='sub_title'>
              <p className='star'>
                <span style={{ fontWeight: 600 }}>
                  AM Best Rating
                  <Tooltip
                    title={
                      StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE
                    }
                  />
                </span>
                <span>-</span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: `${theme.colors.primary}`,
                  }}
                >
                  {msPlan.msCompanyBase.ambestRating}
                </span>
              </p>
            </div>

            <div className='plan_type_mobile'>
              <h2>Plan {msPlan.planName}</h2>
            </div>
          </Header>

          <MonthlyPremiumWrapper isInGrid={isRecommended || isGoodChoice}>
            <div className='premium_details'>
              {monthlyPremium !== -1 && msPlan.id !== msPlan.spousePlanId ? (
                <>
                  {monthlyPremiumDivider(
                    customerStore.firstName,
                    monthlyPremium,
                    'contained',
                    msPlan.msCompanyBase.name,
                    msPlan.planName,
                    primaryEnrollmentStatus,
                    primaryEnrollmentStatus ? 'Application Requested' : 'APPLY',
                    false,
                    'secondary'
                  )}
                  {isUndefined(msPlan.spouseMonthlyPremium) && (
                    <>{hhdAndPolicyFees(msPlan.discounts, msPlan.policyFees)}</>
                  )}
                </>
              ) : (
                <></>
              )}

              {!userOption && monthlyPremium === -1 ? (
                <>
                  {!isUndefined(msPlan.spouseMonthlyPremium) ? (
                    <>
                      {monthlyPremiumDivider(
                        customerStore.spouseFirstName,
                        msPlan.spouseMonthlyPremium / 100,
                        'outlined',
                        msPlan.msCompanyBase.name,
                        msPlan.planName,
                        spouseEnrollmentStatus,
                        spouseEnrollmentStatus
                          ? 'Application Requested'
                          : 'APPLY',
                        true
                      )}

                      {monthlyPremium === -1 ||
                      msPlan.id === msPlan.spousePlanId ? (
                        <>
                          {hhdAndPolicyFees(
                            msPlan.discounts,
                            msPlan.policyFees
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {msPlan.id !== msPlan.spousePlanId &&
              !isUndefined(msPlan.spouseMonthlyPremium) ? (
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{ margin: '0px 10px' }}
                />
              ) : (
                <></>
              )}

              {!isUndefined(msPlan.spouseMonthlyPremium) ? (
                <>
                  {monthlyPremiumDivider(
                    customerStore.spouseFirstName,
                    msPlan.spouseMonthlyPremium / 100,
                    'outlined',
                    msPlan.msCompanyBase.name,
                    msPlan.planName,
                    spouseEnrollmentStatus,
                    spouseEnrollmentStatus ? 'Application Requested' : 'APPLY',
                    true
                  )}

                  {monthlyPremium === -1 ||
                  msPlan.id === msPlan.spousePlanId ? (
                    <>{hhdAndPolicyFees(msPlan.discounts, msPlan.policyFees)}</>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            {customerStore.spouseOptIn ? (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowRemoveSpouseConfirmation(true)
                }}
              >
                - Remove Spouse/Partner
              </NavigationWrapper>
            ) : (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowAddSpouseConfirmation(true)
                }}
              >
                + Add Spouse/Partner
              </NavigationWrapper>
            )}
          </MonthlyPremiumWrapper>
          {StateAndMSPlanTypesConstants.StdPlans.includes(msPlan.planName) &&
            msPlan.planFeatures &&
            msPlan.planFeatures.length > 0 &&
            medigapChartDetails && (
              <Coverage
                heading={`Plan ${msPlan.planName} Benefits`}
                coveragesData={[
                  {
                    name: 'Part A Deductible',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName]
                        .part_a_deductible_1600 !== '0%',
                  },
                  {
                    name: 'Part B Deductible',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName]
                        .part_b_deductible_226 !== '0%',
                  },
                  {
                    name: 'Part B Coinsurance/Co-pay',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName].part_b_copay !==
                        '0%',
                  },
                  {
                    name: 'Part B Excess Charges',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName]
                        .part_b_excess_charges !== '0%',
                  },
                  {
                    name: 'Foreign Travel',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName]
                        .foreign_travel_emergency !== '0%',
                  },
                  {
                    name: 'Skilled Nursing Facility',
                    covered:
                      medigapChartDetails[msPlan.planName] &&
                      medigapChartDetails[msPlan.planName].snf_copay !== '0%',
                  },
                ]}
                isContentExpanded={isPlanBenefitExpanded}
                setIsContentExpanded={handleIsPlanBenefitExpansion}
                toolTipContent={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
              ></Coverage>
            )}
          {(isExpanded ||
            `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
              customerStore.searchPlan) && (
            <>
              <PlanCardFooter
                isInGrid={isGoodChoice || isRecommended}
                planId={msPlan.id}
                id={msPlan.id}
                forSpouse={msPlanFiltersStore.isPrimaryAndSpouseOption}
                spousePlanId={
                  msPlanFiltersStore.isPrimaryAndSpouseOption
                    ? msPlan.spousePlanId
                    : undefined
                }
                isPrimarySaved={msPlan.planSaved}
                favourite={
                  msPlanFiltersStore.isPrimaryAndSpouseOption
                    ? msPlan.spousePlanSaved
                    : msPlan.planSaved
                }
                handleRemoveFavourite={handleRemoveFavorite}
                showCompareCheckbox={showCompareCheckbox}
                planType={msPlan.planName}
                planName={msPlan.msCompanyBase + ' ' + msPlan.planName}
                handleAddToCompare={handleAddToCompare}
                isExpanded={isExpanded}
                handleExpandMSCard={handleExpandCard}
                msPlan={cloneDeep(msPlan)}
                medicareQuoteType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
              />
              <MoreDetailsWrapper>
                {customerStore?.msExpandedCardDetails?.planDetail?.map(
                  (details, index) => (
                    <div key={index}>
                      <p>
                        {details.partType}{' '}
                        <span
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 700,
                            color: `${theme.colors.primary}`,
                          }}
                          onClick={() => {
                            // navigate({
                            //   pathname: '/plan-details',
                            //   search: `id=${msPlan.id}&&type=ms`,
                            // })
                            setCompleteDetailPlanId(msPlan.id)
                            setCompletePlanDetailModalActive(true)

                            storeScrollPosition()
                          }}
                        >
                          View Complete Plan Details
                        </span>
                      </p>
                      <Table style={{ border: '1px solid #E5E5E5' }}>
                        {details.partTypeDetails.map((partTypeDetail) => (
                          <TableRow>
                            <TableCell
                              style={{ borderRight: '1px solid #E5E5E5' }}
                            >
                              {partTypeDetail.key}
                            </TableCell>
                            <TableCell>{partTypeDetail.value}</TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </div>
                  )
                )}
              </MoreDetailsWrapper>
            </>
          )}

          <PlanCardFooter
            isInGrid={isGoodChoice || isRecommended}
            planId={msPlan.id}
            id={msPlan.id}
            favourite={
              msPlanFiltersStore.isPrimaryAndSpouseOption
                ? msPlan.spousePlanSaved
                : msPlan.planSaved
            }
            forSpouse={msPlanFiltersStore.isPrimaryAndSpouseOption}
            isPrimarySaved={
              msPlanFiltersStore.isPrimaryAndSpouseOption
                ? msPlan.planSaved
                : msPlan.planSaved
            }
            spousePlanId={
              msPlanFiltersStore.isPrimaryAndSpouseOption
                ? msPlan.spousePlanId
                : undefined
            }
            handleRemoveFavourite={handleRemoveFavorite}
            showCompareCheckbox={showCompareCheckbox}
            planType={msPlan.planName}
            planName={msPlan.msCompanyBase + ' ' + msPlan.planName}
            handleAddToCompare={handleAddToCompare}
            isExpanded={isExpanded}
            handleExpandMSCard={handleExpandCard}
            msPlan={cloneDeep(msPlan)}
            medicareQuoteType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
          />
        </>
      )}
    </Container>
  )
}

export default observer(MedicareSupplementCard)
