import React, { useEffect } from 'react'
import {
  capitalizeFirstCharacter,
  getApiErrorMessage,
} from '../../utils/StringUtils'

import BouncingDotsLoader from '../common/Loading/BouncingDotsLoader'
import Button from '../common/Button/Button.component'
import Card from '../common/Card/Card.component'
import RadioGroup from '../common/RadioGroup/RadioGroup.component'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import customerStore from '../../datastore/CustomerStore'
import { getProviderAddress } from '../../service/providers'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface ProviderCardProps {
  data: any
  handleEditProviders: (data: Partial<any>) => void
  handleCancel?: () => void
  editProviderLoading: boolean
}

const Container = styled.div``

const Header = styled.div`
  padding-bottom: 20px;
  h2 {
    color: ${theme.colors.primary};
  }
  max-width: 80%;
`

const Body = styled.div`
  background-color: #f2f7fe;
  padding: 10px 15px;
  font-weight: 500;
`
const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -1%;
  margin-right: 2.15%;
  justify-content: end;
  gap: 10px;
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const ProviderCard: React.FC<ProviderCardProps> = ({
  data,
  handleCancel,
  handleEditProviders,
  editProviderLoading,
}) => {
  const [close, setClose] = React.useState(false)
  const [providerAddress, setProviderAddress] = React.useState<any>()
  const [selectedAddress, setSelectedAddress] = React.useState<any>()
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing

  const fetchProviderAddress = () => {
    getProviderAddress({
      id: data.npi,
      searchType: 'cloudsearch',
      type: 'location',
      city: data.city,
      state: data.state,
      lat: data.lat,
      lng: data.lng,
    })
      .then((response: any) => {
        const providerAddress: any[] = []
        response.data.data.map((address: any) => {
          if (address.zip.length === 5) providerAddress.push(address)
        })
        setProviderAddress(providerAddress)
      })
      .catch((err: any) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('get provider address'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
  }

  const getFullAddress = (address1: string, address2: string) => {
    return address1 + ', ' + address2
  }

  const handleEdit = () => {
    if (
      !isEmpty(selectedAddress) &&
      ((data.address2 &&
        getFullAddress(data.address1, data.address2) !== selectedAddress) ||
        (!data.address2 && data.address1 !== selectedAddress))
    ) {
      const temp = providerAddress.filter((address: any) => {
        return address.address2
          ? getFullAddress(address.address1, address.address2) +
              '_ext_' +
              address.id ===
              selectedAddress
          : address.address1 + '_ext_' + address.id === selectedAddress
      })
      handleEditProviders(temp[0])
    } else {
      snackbarStore.set({
        snackbarMessage: StringConstants.SNACKBAR_NO_DATA_TO_SAVE_MSG,
        snackbarOpen: true,
        snackbarType: SnackbarTypes.WARNING,
      })
    }
  }

  useEffect(() => {
    fetchProviderAddress()
  }, [])

  return (
    <Card close={close} closeCard={handleCancel} borderWidth='0'>
      <Container>
        <Header>
          {!isEmpty(providerAddress) && data ? (
            <>
              <h2>{capitalizeFirstCharacter(data.name)}</h2>
              <>
                <RadioButtonWrapper>
                  <RadioGroup
                    formLabel={
                      providerAddress.length > 0
                        ? `This doctor has ${providerAddress.length} office(s). Select the office location where you visit them`
                        : `This doctor does not have an office in the location provided`
                    }
                    radioButtons={providerAddress.map((address: any) => {
                      return (
                        (address.address2
                          ? getFullAddress(address.address1, address.address2)
                          : address.address1) +
                        '_ext_' +
                        address.id
                      )
                    })}
                    value={
                      (data.address2
                        ? getFullAddress(data.address1, data.address2)
                        : data.address1) +
                      '_ext_' +
                      data.externalId
                    }
                    width='100%'
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    row={false}
                    isValueModified={true}
                    valueToBeRemoved={'_ext_'}
                  />
                </RadioButtonWrapper>
              </>
              <ButtonWrapper>
                {isButtonDisabled && (
                  <DisableText>
                    {StringConstants.DISABLE_BUTTON_MSG}
                  </DisableText>
                )}
                <Button
                  width='fit-content'
                  bold={false}
                  onClick={() => {
                    setClose(true)
                    if (handleCancel) {
                      handleCancel()
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  width='fit-content'
                  bold={false}
                  onClick={handleEdit}
                  loading={editProviderLoading}
                  disabled={isButtonDisabled}
                >
                  Save Changes
                </Button>
              </ButtonWrapper>
            </>
          ) : (
            <BouncingDotsLoader />
          )}
        </Header>
      </Container>
    </Card>
  )
}

export default observer(ProviderCard)
