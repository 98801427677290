import { ActionContext } from '../../cra'
import AddedDrugsCardInfoSteps from './AddedDrugsCardInfoSteps'
import BouncingDotsLoader from '../common/Loading/BouncingDotsLoader'
import React from 'react'
import SearchPromptInfoSteps from './SearchPromptInfoSteps.Component'
import SecureComponent from '../../pages/common/SecureComponent'
import customerStore from '../../datastore/CustomerStore'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface AddPrescriptionInfoStepProps {
  handleNext?: () => void
  isSpouse?: boolean
  redirect?: boolean
  pathContext?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const UpperDiv = styled.div`
  width: 100%;
`

const AddedDrugsWrapper = styled.div`
  border-top: 1px solid ${theme.colors.borderColor};
  padding-top: 20px;
`

const AddedDrugsHeader = styled.div`
  font-weight: 600;
  display: flex;
  font-size: 16px;
`

const DrugListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const AddPrescriptionInfoSteps: React.FC<AddPrescriptionInfoStepProps> = ({
  handleNext,
  isSpouse,
  redirect = true,
  pathContext = 'Profile',
}) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [addDrugLoading, setAddDrugLoading] = React.useState<boolean>(false)
  const [deleteDrugLoading, setDeleteDrugLoading] =
    React.useState<boolean>(false)
  const [isEditDrug, setIsEditDrug] = React.useState(false)
  const [addPrescriptionState, setAddPrescriptionState] = React.useState<{
    AddedDrugs: any[]
    PrescriptionDrug: any[]
    addingDrugs: boolean
  }>({
    AddedDrugs: [],
    PrescriptionDrug: [],
    addingDrugs: false,
  })
  const [forSpouse, setForSpouse] = React.useState(
    isUndefined(isSpouse) ? customerStore.forSpouse : isSpouse
  )

  const { trackCurrentPage } = React.useContext(ActionContext)

  React.useEffect(() => {
    const spouse = isUndefined(isSpouse) ? customerStore.forSpouse : isSpouse
    trackCurrentPage(
      `${pathContext === 'infoSteps' ? 'Info Steps -' : 'Profile -'} ${
        spouse ? 'Spouse/Partner’s Prescriptions' : 'Prescriptions'
      }`
    )
    setForSpouse(spouse)
  }, [customerStore.forSpouse, isSpouse])

  return (
    <Container>
      <UpperDiv>
        <SearchPromptInfoSteps
          addPrescriptionState={addPrescriptionState}
          setAddPrescriptionState={setAddPrescriptionState}
          handleNext={handleNext}
          forSpouse={forSpouse}
          loading={loading}
          setLoading={setLoading}
          redirect={redirect}
          addDrugLoading={addDrugLoading}
          setAddDrugLoading={setAddDrugLoading}
          setIsEditDrug={setIsEditDrug}
          isEditDrug={isEditDrug}
        />

        <AddedDrugsWrapper>
          {loading ? (
            <BouncingDotsLoader />
          ) : (
            <>
              <AddedDrugsHeader>
                Added Drugs: {addPrescriptionState.AddedDrugs.length}
              </AddedDrugsHeader>

              {addDrugLoading || deleteDrugLoading ? (
                <BouncingDotsLoader />
              ) : (
                addPrescriptionState.AddedDrugs.length > 0 && (
                  <DrugListWrapper>
                    {addPrescriptionState.AddedDrugs.map((item, key) => (
                      <AddedDrugsCardInfoSteps
                        greyOut={isEditDrug}
                        key={key}
                        addPrescriptionState={addPrescriptionState}
                        setAddPrescriptionState={setAddPrescriptionState}
                        data={item}
                        setDeleteDrugLoading={setDeleteDrugLoading}
                        setIsEditDrug={setIsEditDrug}
                        forSpouse={forSpouse}
                      />
                    ))}
                  </DrugListWrapper>
                )
              )}
            </>
          )}
        </AddedDrugsWrapper>
      </UpperDiv>
    </Container>
  )
}

export default SecureComponent(observer(withRouter(AddPrescriptionInfoSteps)))
