import { useEffect, useState } from 'react'

import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import { MedicareConstants } from '../../../../constants/MedicareConstants'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const Description = styled.p`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  text-align: left;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
`
interface SelectMABenefitProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  forSpouse: boolean
}
const SelectMABenefit: React.FC<SelectMABenefitProps> = ({
  forSpouse,
  disableMainNext,
  enableMainNext,
  handleHasNext,
}) => {
  const [benefits, setBenefits] = useState(() =>
    MedicareConstants.getMABenefits().map((benefit) => {
      const isChecked = forSpouse
        ? currentPlanStore.getSpouseMABenefits().includes(benefit)
        : currentPlanStore.getMABenefits().includes(benefit)

      return {
        id: benefit,
        checked: isChecked,
        label: benefit,
        value: benefit,
      }
    })
  )

  useEffect(() => {
    const tempBenefits = forSpouse
      ? currentPlanStore.getSpouseMABenefits()
      : currentPlanStore.getMABenefits()
    handleHasNext && handleHasNext(false)
    if (!isEmpty(tempBenefits)) {
      enableMainNext && enableMainNext()
    } else {
      disableMainNext && disableMainNext()
    }
  }, [])

  return (
    <>
      <Description>
        Please choose your Medicare Advantage (HMO, PPO, PFFS) Benefits
      </Description>
      <RadioButtonWrapper>
        <CheckboxGroup
          checkboxStyle={{
            borderRadius: '5px',
            border: 'solid #E5E5E5 1px',
            background: '#FBFBFB',
          }}
          checkboxState={benefits}
          width='100%'
          onChange={(e) => {
            const checkedBenefits = benefits.map((item) =>
              item.value === e.target.value
                ? { ...item, checked: !item.checked }
                : item
            )
            setBenefits(checkedBenefits)

            const result: string[] = []
            checkedBenefits.forEach((benefit) => {
              if (benefit.checked) result.push(benefit.value)
            })

            if (forSpouse) currentPlanStore.setSpouseMABenefits(result)
            else currentPlanStore.setMABenefits(result)

            if (result.length > 0) enableMainNext && enableMainNext()
            else disableMainNext && disableMainNext()
          }}
        />
      </RadioButtonWrapper>
    </>
  )
}

export default observer(SelectMABenefit)
